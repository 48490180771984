import _omit from "lodash/omit";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { Price } from 'swag-client-common/utils/price';
import { browserHistory } from 'react-router';
import { REORDER_WARNING_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { OrderApi } from 'swag-client-common/api/order.api';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { setSuperSpeedProductIdInLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { filterOrdersByPeriodAndLvl, getInitialPeriod, mapDateToMilliseconds, prepareItemToReorder, sortOrdersByTime, isItemExistInCartWithForPromo, getRealItemsInsideBoxProdIds, getItemsAvailableForReorder, checkIfItemAvailableForReorder } from '../account-orders/account-orders.utils';
import ProductApi from '../../api/product.api';
import { showCustomerMockups } from '../show-mockups/show-mockups.actions';
import { REORDER_ITEM_REQUESTED, reorderCustomBox, reorderSampleItem } from '../account-orders/account-orders.actions';
import { singleItemInOrderSelector, companyOrdersSearchSelector, companyOrdersPaginationSelector } from './company-orders.selectors';
const PREFIX = 'COMPANY_';
export const UPDATE_EXPORT_DATE = `${PREFIX}UPDATE_EXPORT_DATE`;
export const updateExportDate = (dateType, dateValue) => ({
  type: UPDATE_EXPORT_DATE,
  payload: {
    dateType,
    dateValue
  }
});
export const FETCH_USERS_ORDERS_REQUEST = `${PREFIX}FETCH_USERS_ORDERS_REQUEST`;
export const FETCH_USERS_ORDERS_SUCCESS = `${PREFIX}FETCH_USERS_ORDERS_SUCCESS`;
export const FETCH_USERS_ORDERS_ERROR = `${PREFIX}FETCH_USERS_ORDERS_ERROR`;
export const fetchCompanyOrders = createAsyncAction({
  transformData: (data = {}, getState) => {
    const state = getState();
    const {
      page,
      perPage
    } = companyOrdersPaginationSelector(state);
    const companyEmailDomain = state.user.companyEmailDomain;
    const search = companyOrdersSearchSelector(state);
    return _objectSpread({
      companyEmailDomain,
      perPage,
      page,
      search
    }, data);
  },
  preFn: (dispatch, getState) => {
    const {
      cart,
      user
    } = getState();
    if (cart.orderToken === null && !user.email) {
      return true;
    }
    dispatch({
      type: FETCH_USERS_ORDERS_REQUEST
    });
  },
  fn: OrderApi.getByCompany,
  success: ({
    orders,
    pagination
  }) => ({
    type: FETCH_USERS_ORDERS_SUCCESS,
    payload: {
      timePeriod: getInitialPeriod(mapDateToMilliseconds(orders)),
      orders: sortOrdersByTime(mapDateToMilliseconds(orders)),
      hasNoOrders: orders.length === 0,
      pagination
    }
  }),
  postSuccess: () => {
    window.scrollTo({
      top: 0,
      left: 0
    });
  },
  error: error => ({
    type: FETCH_USERS_ORDERS_ERROR,
    payload: error,
    error
  })
});
export const ORDERS_FILTERED = `${PREFIX}ORDERS_FILTERED`;
export const filterOrders = ({
  period = undefined,
  lvl = undefined
}) => (dispatch, getState) => {
  const {
    orders,
    searchTerm,
    timePeriod,
    selectedLvl
  } = getState().companyOrders;
  let ordersToDisplay;
  ordersToDisplay = sortOrdersByTime(mapDateToMilliseconds(filterOrdersByPeriodAndLvl(orders, period || timePeriod, lvl || selectedLvl)));
  if (searchTerm) {
    ordersToDisplay = findOrderBySearchTerm(searchTerm, ordersToDisplay);
  }
  dispatch({
    type: ORDERS_FILTERED,
    payload: {
      timePeriod: period || timePeriod,
      selectedLvl: lvl || selectedLvl,
      ordersToDisplay
    }
  });
};
const findOrderBySearchTerm = (search, orders) => {
  if (!search) {
    return orders;
  }
  const searchTerm = search.toLowerCase();
  return orders.filter(order => order.num && order.num.indexOf(searchTerm) > -1 || order.email.toLowerCase().indexOf(searchTerm) > -1 || `${order.firstName} ${order.lastName}`.toLowerCase().indexOf(searchTerm) > -1 || order.items.find(item => item.prod.name.toLowerCase().indexOf(searchTerm) > -1));
};
export const COMPANY_ORDER_SEARCH = `${PREFIX}COMPANY_ORDER_SEARCH`;
export const search = searchTerm => (dispatch, getState) => {
  const {
    orders,
    timePeriod,
    selectedLvl
  } = getState().companyOrders;
  const sortedOrders = sortOrdersByTime(mapDateToMilliseconds(filterOrdersByPeriodAndLvl(orders, timePeriod, selectedLvl)));
  const ordersToDisplay = findOrderBySearchTerm(searchTerm, sortedOrders);
  return dispatch({
    type: COMPANY_ORDER_SEARCH,
    payload: {
      ordersToDisplay,
      searchTerm
    }
  });
};
export const requestReorder = ({
  orderId,
  itemId,
  isSuperSpeed
}) => async (dispatch, getState) => {
  const state = getState();
  const item = singleItemInOrderSelector(state, {
    orderId,
    itemId
  });
  if (isBox(item)) {
    const realItemsInsideBoxProdIds = getRealItemsInsideBoxProdIds(item);
    let productsToReorder = [];
    if (realItemsInsideBoxProdIds.length === 1) {
      const {
        product
      } = await ProductApi.getById({
        id: realItemsInsideBoxProdIds[0]
      });
      productsToReorder = [product];
    } else {
      const {
        products
      } = await ProductApi.getByIds({
        ids: realItemsInsideBoxProdIds
      });
      productsToReorder = products;
    }
    const {
      unavailableItems,
      isReorderAvailable
    } = getItemsAvailableForReorder(productsToReorder);
    if (unavailableItems.length) {
      return dispatch(showPopup({
        popup: REORDER_WARNING_POPUP,
        item,
        isBox: true,
        unavailableItems,
        isReorderAvailable
      }));
    }
    return dispatch(reorderCustomBox(item, {
      reorderId: orderId
    }));
  }
  if (item.isSample) {
    return dispatch(reorderSampleItem({
      item
    }));
  }
  const {
    product
  } = await ProductApi.getById({
    id: item.prodId
  });
  const isItemAvailableForReorder = checkIfItemAvailableForReorder(product);
  if (!isItemAvailableForReorder) {
    return dispatch(showPopup({
      popup: REORDER_WARNING_POPUP,
      item,
      isBox: false,
      unavailableItems: [product]
    }));
  }
  if (isSuperSpeed) {
    var _item$prod;
    setSuperSpeedProductIdInLS((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id);
  }
  return dispatch(reorderItem({
    item
  }, orderId));
};
export const reorderItem = ({
  item
}, orderId) => (dispatch, getState) => {
  const {
    prod: {
      seoSlug
    },
    prodId
  } = item;
  const {
    cart: {
      items
    },
    companyOrders: {
      orders
    }
  } = getState();
  const {
    promocodeDiscount,
    measurement,
    items: orderItems
  } = orders.find(companyOrder => companyOrder._id === orderId);
  const productLink = seoSlug || prodId;
  const preparedItem = _objectSpread({}, prepareItemToReorder(item), {
    reorderId: orderId
  });
  const promoIsNotAvailable = isItemExistInCartWithForPromo(items, preparedItem) && preparedItem.readyForPromo;
  if (promoIsNotAvailable) {
    preparedItem.readyForPromo = false;
  }
  if (promocodeDiscount) {
    const discount = measurement === PromocodeMeasurementEnum.ABSOLUTE && Math.round(promocodeDiscount / orderItems.length) || promocodeDiscount;
    preparedItem.price = Price.applyDiscount(preparedItem.price, discount, measurement);
  }
  const itemWithoutSetupFeeField = _omit(item, 'setupFeePerItem');
  const allOrderItems = (items === null || items === void 0 ? void 0 : items.map(i => _omit(i, 'setupFeePerItem'))) || [];
  if (!allOrderItems.find(i => i._id === itemWithoutSetupFeeField._id)) {
    allOrderItems.push(itemWithoutSetupFeeField);
  }
  preparedItem.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount({
    item: itemWithoutSetupFeeField,
    allOrderItems
  });
  browserHistory.push(`${APP_ROUTES.PRODUCT}/${productLink}`);
  dispatch({
    type: REORDER_ITEM_REQUESTED,
    payload: {
      item: prepareItemToReorder(preparedItem)
    }
  });
};
export const showItemImage = (orderId, itemId) => (dispatch, getState) => {
  const item = singleItemInOrderSelector(getState(), {
    orderId,
    itemId
  });
  dispatch(showCustomerMockups(item));
};
export const RESET_ORDERS = `${PREFIX}RESET_ORDERS`;
export const resetCompanyOrders = () => ({
  type: RESET_ORDERS
});
export const SEARCH_CHANGE = `${PREFIX}SEARCH_CHANGE`;
export const changeSearch = value => dispatch => {
  dispatch({
    type: SEARCH_CHANGE,
    payload: {
      search: value
    }
  });
};