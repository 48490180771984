import _startCase from "lodash/startCase";
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import { tenantEmailNameMap } from 'swag-common/constants/tenant-email-name';
import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
import { getSubStringAfterSymbol } from 'swag-common/utils/strings';
import { MAX_EMAIL_LENGTH } from 'swag-common/constants/validations';
import { IS_SANDBOX, IS_STAGING, IS_TEST } from '../config/constants';
export const isAutoTestRegex = /^autotest.*@(eze\.tech|aleph1\.io)$/;
export const shouldSkipSending = email => (IS_TEST || IS_STAGING || IS_SANDBOX) && isAutoTestRegex.test(email);
export const getTenantName = (tenant, tenantStoreSettings) => {
  var _tenantEmailNameMap$t;
  if (checkIsResellerTenant(tenant) && tenantStoreSettings !== null && tenantStoreSettings !== void 0 && tenantStoreSettings.storeName) {
    return tenantStoreSettings !== null && tenantStoreSettings !== void 0 && tenantStoreSettings.companyName ? _startCase(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : tenantStoreSettings.companyName) : _startCase(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : tenantStoreSettings.storeName);
  }
  const emailTenantName = (_tenantEmailNameMap$t = tenantEmailNameMap[tenant]) !== null && _tenantEmailNameMap$t !== void 0 ? _tenantEmailNameMap$t : tenantEmailNameMap[PREDEFINED_TENANTS.SWAG];
  return emailTenantName;
};
export const getDomainFromEmail = email => getSubStringAfterSymbol(email, '@');
export const validateEmail = value => {
  let error = /^.+@.+\..+$/.test(value) && !value.includes(' ') ? null : "Email you entered doesn't seem to be valid";
  if (!error) {
    error = value.length < MAX_EMAIL_LENGTH ? null : `Email can't be longer than ${MAX_EMAIL_LENGTH} symbols`;
  }
  return error;
};
export const normalizeEmail = email => {
  var _email$trim$toLowerCa, _email$trim;
  return (_email$trim$toLowerCa = email === null || email === void 0 ? void 0 : (_email$trim = email.trim()) === null || _email$trim === void 0 ? void 0 : _email$trim.toLowerCase()) !== null && _email$trim$toLowerCa !== void 0 ? _email$trim$toLowerCa : '';
};