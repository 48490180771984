import * as utils from 'swag-common/utils/strings';
import { MIN_PASSWORD_LENGTH, ENTIRE_PASSWORD_REGEXP, MAX_PASSWORD_LENGTH } from 'swag-common/constants/validations';
import { validateEmail } from 'swag-common/utils/email';
import { validateZipCodeByCountry } from './validate-zip-code-by-country';
const NOTES_LENGTH = 500;
export const required = value => value && (typeof value !== 'string' || !!value.trim()) ? null : 'This field is required';
export const requiredIfValue = fieldName => (value, values) => {
  const conditionValue = values[fieldName];
  return !conditionValue ? null : required(value);
};
export const notEmpty = value => value ? null : ' ';
export const email = validateEmail;
export const phoneNumber = value => {
  if (value && !/^[\s\d,()\-+]*$/.test(value)) {
    return 'Phone number contains restricted characters';
  }
  if (value && value.length > 30) {
    return 'Phone number length should not exceed 30 symbols';
  }
  return null;
};
export const password = value => !value || value.length < MIN_PASSWORD_LENGTH || value.length > MAX_PASSWORD_LENGTH || !ENTIRE_PASSWORD_REGEXP.test(value) ? 'Invalid password' : null;
export const passwordRepeat = passwordFieldName => (confirmPassword, values) => {
  const passwordValue = values[passwordFieldName];
  return passwordValue && confirmPassword === passwordValue ? null : 'Passwords should match';
};
export const inventoryTermsAgreed = agreed => agreed ? null : 'You should agree with inventory terms';
export const passwordNotRepeat = passwordFieldName => (confirmPassword, values) => {
  const passwordValue = values[passwordFieldName];
  return passwordValue && confirmPassword === passwordValue ? "Password shouldn't match old one" : null;
};
export const zip = value => value && value.length > 15 ? 'Invalid zip' : null;
export const zipByCountry = (zip, {
  country
}) => {
  return validateZipCodeByCountry(zip, country);
};
export const mailDomain = domain => {
  const regExp = new RegExp(`^[A-Za-z0-9._%+-]+@${domain}$`);
  return value => regExp.test(value) ? null : `Email should belong to ${domain} domain`;
};
export const maxLength = max => value => {
  if (!value || value.trim && !value.trim()) {
    return null;
  }
  return value && value.length <= max ? null : `Maximum ${max} symbols`;
};
export const maxLengthCharacters = max => value => {
  if (!value || value.trim && !value.trim()) {
    return null;
  }
  return value && value.length <= max ? null : `Maximum ${max} characters`;
};
export const minLength = min => value => {
  if (!value || value.trim && !value.trim()) {
    return null;
  }
  return value && value.length >= min ? null : `Minimum ${min} symbols`;
};
export const url = value => {
  const regExp = new RegExp('(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&=]*)');
  return regExp.test(value) ? null : 'Website should be in correct format';
};
export const number = value => Number.isNaN(value) ? 'Please, enter valid number' : null;
export const nonNegativeNumber = value => {
  if (Number.isNaN(value)) {
    return 'Please, enter valid number';
  }
  if (value < 0) {
    return 'Please enter non negative number';
  }
  return null;
};
export const date = value => {
  const currentDate = new Date();
  const selectedDate = new Date(value);
  return value && currentDate > selectedDate ? 'Please, select the date that starts from tomorrow or later' : null;
};
export const isColor = value => {
  const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(value);
  return isValid ? null : 'Color should be in correct format';
};
export const maxScoreNumber = max => value => {
  if (!value || !max || value.trim && !value.trim()) {
    return null;
  }
  const score = value.replace(/[^\d]/g, '') * 100;
  return score && score < max ? null : `Need to be smaller than ${utils.normalizeNumber(`${max}`)}`;
};
export const minScoreNumber = min => value => {
  if (!value || !min || value.trim && !value.trim()) {
    return null;
  }
  const score = value.replace(/[^\d]/g, '') * 100;
  return score && score > min ? null : `Need to be higher than ${utils.normalizeNumber(`${min}`)}`;
};
export const maxNumber = max => value => {
  return !value || !max || value <= max ? null : `Should be lower than ${max}`;
};
export const minNumber = min => value => {
  return !value || !min || value >= min ? null : `Should be greater than ${min}`;
};
export const orderNum = value => {
  const isValid = /^\d{5}$/.test(value);
  return isValid ? '' : 'Should be 5 digits number';
};
export const notesValidate = isRequired => (value = '') => {
  if (isRequired && !value) {
    return 'Order note is mandatory if Specialized Fulfillment is selected';
  }
  if (value.length > NOTES_LENGTH) {
    return `Notes can't be longer than ${NOTES_LENGTH} symbols`;
  }
  return null;
};