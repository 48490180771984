function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-common/utils/price';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { areLogosTheSame } from 'swag-common/business-logic/order/is-order-more-items-initial.logic';
import { getAbsoluteDiscount, getTotalBeforeDiscount, orderTotalPrice } from 'swag-common/utils/order/order-summary-helpers.util';
import { productionCostCalculate } from 'swag-common/utils/order/production-cost-calculate.utils';
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { LOGO_UPLOAD_BLANK_OPTIONS } from 'swag-common/constants/product.constants';
import { calculateSetupFeeForSwagItem } from 'swag-common/business-logic/calculate-setup-fee-value.logic';
export const getItemVariantQuantities = ({
  hasSize,
  variants,
  size
}) => {
  const variantsQuantities = hasSize ? variants.map(variant => (size.options || []).reduce((acc, sizeName, index) => [...acc, {
    size: sizeName,
    sizeQuantity: variant.quantities[index]
  }], [])) : variants.map(({
    quantities
  }) => quantities);
  const totalQuantities = variants.map(variant => {
    return variant.quantities.reduce((all, next) => all + next, 0);
  });
  return {
    variantsQuantities,
    totalQuantities
  };
};
export const subtractQuantities = ({
  decreasingQuantity,
  subtractionQuantity
}, preventNegativeValues = true) => decreasingQuantity.map((value, index) => {
  const result = value - ((subtractionQuantity === null || subtractionQuantity === void 0 ? void 0 : subtractionQuantity[index]) || 0);
  if (!preventNegativeValues) {
    return result;
  }
  return result < 0 ? 0 : result;
});
export const addQuantities = ({
  firstQuantities,
  secondsQuantities
}) => {
  return firstQuantities === null || firstQuantities === void 0 ? void 0 : firstQuantities.map((quantity, index) => {
    return quantity + (secondsQuantities[index] || 0);
  });
};
export function checkIsCommonValuesForSharedItemsHaveChanged(firstItem, secondItem, isLogoDimensionsModified = false) {
  var _firstItem$prod, _firstItem$prod2;
  if (firstItem !== null && firstItem !== void 0 && (_firstItem$prod = firstItem.prod) !== null && _firstItem$prod !== void 0 && _firstItem$prod.variantDependentPrice && secondItem.variantInd !== firstItem.variantInd) {
    return true;
  }
  if (firstItem.embellishmentMethod !== secondItem.embellishmentMethod) {
    return true;
  }
  if (firstItem.isCustomizedBlank !== secondItem.isCustomizedBlank) {
    return true;
  }
  if (firstItem !== null && firstItem !== void 0 && (_firstItem$prod2 = firstItem.prod) !== null && _firstItem$prod2 !== void 0 && _firstItem$prod2.sizeDependentPrice && firstItem.sizeInd !== secondItem.sizeInd) {
    return true;
  }
  if (firstItem.prodTime !== secondItem.prodTime) {
    return true;
  }
  if (!areLogosTheSame(firstItem.logos, secondItem.logos) || isLogoDimensionsModified) {
    return true;
  }
  return false;
}
export const getItemPriceInfo = (origItem, allOrderItems) => {
  var _item$prod, _item$tax, _item$sampleDeduction, _item$absoluteItemDis;
  const item = _objectSpread({}, origItem);
  if (item.IS_FREE_SETUP_FEE) {
    item.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount({
      item,
      allOrderItems
    });
  }
  const productionCost = productionCostCalculate(item);
  const setupFee = calculateSetupFeeForSwagItem({
    item,
    allOrderItems
  });
  const screenPrice = Price.getScreenPriceForItem({
    item: item,
    setupFee
  });
  const itemPrice = Price.getPricePerItem({
    assemblyFee: item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.assemblyFee,
    quantity: item.quantity,
    screenPrice,
    totalPriceForItems: productionCost,
    item,
    allOrderItems
  });
  const shipping = getItemShippingCost(getDataForGettingItemShippingCost(item, [item]));
  const tax = (_item$tax = item.tax) !== null && _item$tax !== void 0 ? _item$tax : 0;
  const totalBeforeDiscount = getTotalBeforeDiscount(productionCost, shipping, tax, 0);
  const onlyDeductionsValue = (item.IS_FREE_SHIPPING ? shipping : 0) + (item.IS_TAXFREE ? tax : 0) + ((_item$sampleDeduction = item === null || item === void 0 ? void 0 : item.sampleDeductionDiscount) !== null && _item$sampleDeduction !== void 0 ? _item$sampleDeduction : 0);
  const discount = getAbsoluteDiscount((_item$absoluteItemDis = item.absoluteItemDiscount) !== null && _item$absoluteItemDis !== void 0 ? _item$absoluteItemDis : 0, totalBeforeDiscount, onlyDeductionsValue);
  const total = orderTotalPrice(totalBeforeDiscount, discount + onlyDeductionsValue);
  const qty = item.quantity;
  const isFreeShipping = !!item.IS_FREE_SHIPPING;
  const isFreeTax = !!item.IS_TAXFREE;
  const isFreeSetupFee = !!item.IS_FREE_SETUP_FEE;
  return {
    total,
    qty,
    productionCost,
    setupFee,
    itemPrice,
    shipping,
    tax,
    discount,
    isFreeShipping,
    isFreeTax,
    isFreeSetupFee
  };
};
const getPercentValue = (costDiff, productionCost) => {
  if (costDiff === 0 || productionCost === 0) {
    return 0;
  }
  return Number((costDiff / productionCost * 100).toFixed(2));
};
const calculateFreeReplacement = ({
  replacementItem,
  originalItem
}) => {
  const productionCostDiff = replacementItem.productionCost - originalItem.productionCost;
  if (replacementItem.total > originalItem.total) {
    const percentValue = getPercentValue(productionCostDiff, originalItem.productionCost);
    const totalDiff = replacementItem.total - originalItem.total;
    return {
      percentValue,
      absoluteValue: Price.formatPrice(totalDiff)
    };
  }
  const percentValue = getPercentValue(productionCostDiff, originalItem.productionCost);
  const totalDiff = originalItem.total - replacementItem.total;
  return {
    percentValue,
    absoluteValue: Price.formatPrice(totalDiff)
  };
};
export const getDifferenceStr = ({
  freeReplacementPercent,
  replacementItem,
  originalItem
}) => {
  if (!originalItem.total) {
    return {
      percentStr: 'N/A',
      absoluteStr: ''
    };
  }
  const diff = calculateFreeReplacement({
    replacementItem,
    originalItem
  });
  if (!originalItem.productionCost) {
    return {
      percentStr: '100%',
      absoluteStr: ` (Customer will have to pay ${diff.absoluteValue} extra).`
    };
  }
  if (originalItem.total > replacementItem.total) {
    return {
      percentStr: `${diff.percentValue}%`,
      absoluteStr: ` (Customer will be refunded ${diff.absoluteValue}).`
    };
  }
  if (replacementItem.total > originalItem.total) {
    if (diff.percentValue > freeReplacementPercent) {
      return {
        percentStr: `${diff.percentValue}%`,
        absoluteStr: ` (Customer will have to pay ${diff.absoluteValue} extra).`
      };
    }
    return {
      percentStr: `${diff.percentValue}%`,
      absoluteStr: ` (Customer gets a discount of ${diff.absoluteValue}).`
    };
  }
  return {
    percentStr: '0.00%',
    absoluteStr: ''
  };
};
export const getFinalTotal = ({
  freeReplacementPercent,
  replacementItem,
  originalItem
}) => {
  const diff = calculateFreeReplacement({
    replacementItem,
    originalItem
  });
  if (originalItem.productionCost && replacementItem.total > originalItem.total && diff.percentValue <= freeReplacementPercent) {
    return originalItem.total;
  }
  return replacementItem.total;
};
export function isLogoRequired(item) {
  var _item$prod2;
  return !item.isHidden && !item.isSample && !isFullDesign(item.prod) && !isBox(item.prod) && (item === null || item === void 0 ? void 0 : (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.logoUploadBlankOption) === LOGO_UPLOAD_BLANK_OPTIONS.ONLY_PRINTED;
}