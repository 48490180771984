import { calculateSetupFeePerItem } from 'swag-common/business-logic';
import { DesignUploadMode } from 'swag-common/interfaces';
export default function getSetupFeeDeductionDiscount({
  item,
  allOrderItems,
  featureFlags
}) {
  const {
    prod,
    isSample,
    isPrintedSample,
    products
  } = item;
  const isSampleItem = isSample || isPrintedSample;
  const isBoxTypeProduct = (prod === null || prod === void 0 ? void 0 : prod.designUploadMode) === DesignUploadMode.box && !isSampleItem;
  const isLogoTypeProduct = (prod === null || prod === void 0 ? void 0 : prod.designUploadMode) === DesignUploadMode.logo && !isSampleItem;
  let setupFee = 0;
  if (isBoxTypeProduct && products) {
    setupFee = products.reduce((all, product) => {
      return all + calculateSetupFeePerItem({
        item,
        allOrderItems,
        featureFlags
      });
    }, 0);
  }
  if (isLogoTypeProduct) {
    setupFee = calculateSetupFeePerItem({
      item,
      allOrderItems,
      featureFlags
    });
  }
  return Math.round(setupFee);
}