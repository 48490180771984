import _map from "lodash/map";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-client-common/utils/price';
export const map = {
  0: 'front',
  1: 'back',
  2: 'left',
  3: 'right'
};
export const updateLocations = (locationColors, locations) => {
  const values = [1, 1, 1, 1];
  for (let i = 0; i < locations; i++) {
    if (locationColors[i] !== 0) {
      values[i] = locationColors[i];
    }
  }
  for (let i = locations; i < values.length; i++) {
    values[i] = 0;
  }
  const data = getLogosData(values);
  return {
    images: data,
    logos: data,
    zeroIndex: values.findIndex(v => v === 0)
  };
};
export const updateColor = (locationColors, colors, index) => {
  const values = _map(locationColors);
  values[index] = colors;
  const data = getLogosData(values, colors);
  return {
    images: data,
    logos: data,
    zeroIndex: values.findIndex(v => v === 0)
  };
};
export const calcPrices = (prods, orderItem) => {
  const {
    quantity
  } = orderItem;
  return prods.map(p => {
    const total = Price.getPrice({
      orderItem: orderItem,
      product: p.data,
      // just pass empty array for this legacy code
      allOrderItems: []
    });
    return _objectSpread({}, p, {
      price: Math.round(total / quantity),
      discountPrice: Math.round(total * 80 / 100 / quantity)
    });
  });
};
const MIN_AMOUNT = 0;
const MAX_AMOUNT = 9999;
const MIN_LOCATIONS = 0;
const MAX_LOCATIONS = 4;
const MIN_COLORS = 0;
const MAX_COLORS = 999;
export const checkValid = (field, value) => {
  switch (field) {
    case 'amount':
      return value >= MIN_AMOUNT && value <= MAX_AMOUNT;
    case 'locations':
      return value >= MIN_LOCATIONS && value <= MAX_LOCATIONS;
    case 'colors':
      return value >= MIN_COLORS && value <= MAX_COLORS;
    default:
      return false;
  }
};
const getLogosData = (values, colors = 0) => Object.values(map).reduce((all, nextLocation, key) => {
  const locationData = {
    colors: {
      colorsNumber: values[key] || 0,
      colorsToPrint: []
    }
  };
  if (values[key]) {
    // @todo. auto generated. fix this error
    // @ts-ignore
    locationData.colors.colorsToPrint = Array(colors || values[key]).fill(0).map((v, i) => `${nextLocation}-${i}`);
  } else {
    locationData.colors.colorsToPrint = [];
  }
  return _objectSpread({}, all, {
    [nextLocation]: [locationData]
  });
}, {});