import _uniq2 from "lodash/uniq";
import _isEmpty2 from "lodash/isEmpty";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { Price } from 'swag-client-common/utils/price';
import pickSkuForLineItem from 'swag-common/business-logic/order/pick-sku-for-line-item.logic';
import { getBoxItems, isBox } from 'swag-common/utils/order/item-is-box.util';
import { getModifiedItemNameAndOriginal } from 'swag-common/business-logic/inventory-product-name/get-modified-item-name-and-original.logic';
import checkIsSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { DesignUploadMode } from 'swag-common/interfaces';
import { OrderStorageTypes } from 'swag-common/constants/main-swag.constants';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { getOrderItemImage } from 'swag-common/business-logic/items/get-order-item-image.logic';
import { checkIsApparel } from 'swag-client-common/utils/product.utils';
import { checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { checkIsSticker } from 'swag-common/utils/product/product.utils';
import { TOOLTIP_INDIVIDUAL_ITEM_NAME, getTooltipItemName } from '../../utils/get-tooltip-item-name';
import { getSplitShippedToCustomerCouple } from '../../utils/get-upsell-widget-data/check-is-split-shipped-item-to-customer-has-inventory-couple';
export { getRelatedItemsPricing, getSetupFeeForRelatedItems } from 'swag-common/business-logic';
export const getImageOfItem = item => {
  var _variants$;
  const {
    customerMockups,
    prod,
    sizeInd,
    variants
  } = item;
  const variantInd = (_variants$ = variants[0]) === null || _variants$ === void 0 ? void 0 : _variants$.variantIndex;
  const mockup = getOrderItemImage({
    product: prod,
    customerMockups,
    variantInd,
    sizeInd
  });
  return mockup.url;
};
export const isItemSizeEditable = item => {
  return item.prod.hasSize || item.products && item.products.some(p => p.prod.hasSize);
};
const isInventoryItem = item => {
  return Array.isArray(item) && item.length > 0 ? Boolean(item[0].inventoryId) : Boolean(item.inventoryId);
};
export const isItemGoingToInventory = item => {
  if (Array.isArray(item)) {
    const [firstItem] = item;
    return isInventoryItem(firstItem);
  }
  return false;
};
export const getBreakdown = item => {
  const productName = isBox(item) ? item.name : item.prod.name;
  return {
    name: getModifiedItemNameAndOriginal(productName, item.modifiedProductName),
    _id: item.prod._id
  };
};
export const getItemFromSpecialBoxProduct = ({
  boxItemItself: {
    quantity,
    boxItemsId,
    isReorder
  },
  specialBoxProduct,
  variantIndex = 0,
  notecardDesignData,
  allOrderItems
}) => {
  const isBoxNotecard = specialBoxProduct.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
  const orderItem = {
    texts: {},
    logos: {},
    colors: 0,
    prodId: specialBoxProduct._id,
    itemSku: pickSkuForLineItem(specialBoxProduct, [{
      variantIndex
    }]),
    sizeInd: 0,
    quantity,
    isReorder,
    discount: false,
    attachments: [],
    blendedQuantity: quantity,
    setupFeeDeductionDiscount: 0,
    // @TODO: maybe not 0 in case of reorder
    boxItemsId,
    isMainItem: true,
    variants: [{
      variantIndex,
      quantities: [quantity]
    }],
    designNotes: '',
    prodTime: 0,
    quantityPerBox: 1,
    prod: specialBoxProduct,
    isCustomizedBlank: true,
    initialCreationDate: Date.now()
  };
  orderItem.price = Price.getPrice({
    orderItem: orderItem,
    product: specialBoxProduct,
    allOrderItems
  });
  orderItem.blendedPrice = Price.getPrice({
    orderItem: orderItem,
    product: specialBoxProduct,
    blendedQuantity: quantity,
    screenPrice: 0,
    allOrderItems
  });
  if (isBoxNotecard && notecardDesignData) {
    const {
        logo
      } = notecardDesignData,
      rest = _objectWithoutProperties(notecardDesignData, ["logo"]);
    orderItem.notecardDesignData = _objectSpread({}, rest);
    const logos = logo ? {
      front: [logo]
    } : {};
    orderItem.logos = logos;
  }
  return orderItem;
};
export function getAllRelatedItems(allItems, currentItem) {
  if (!currentItem) {
    return [];
  }
  return allItems.filter(item => item.asRelatedItemId === currentItem.asRelatedItemId && item.prodId !== currentItem.prodId);
}
export function getAllSharedItems(allItems, currentItem) {
  if (!currentItem) {
    return [];
  }
  return allItems.filter(item => item.asRelatedItemId === currentItem.asRelatedItemId && item.prodId === currentItem.prodId);
}
export const checkIfConflictRelateToBox = (conflictMessages, items) => {
  if (_isEmpty2(conflictMessages)) {
    return false;
  }
  const itemsIds = _uniq2(items.map(({
    boxItemsId
  }) => boxItemsId));
  return Boolean(Object.entries(conflictMessages).find(([, value]) => !value.isSuccessed && itemsIds.includes(value.boxItemsId)));
};
export const getVariantsInfo = item => (item.variants || []).map(({
  variantIndex,
  quantities
}) => {
  var _item$prod, _item$prod$variants;
  const quantity = quantities.reduce((a, b) => a + b);
  const variant = item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$variants = _item$prod.variants) === null || _item$prod$variants === void 0 ? void 0 : _item$prod$variants[variantIndex];
  if (!variant) {
    return {
      quantity
    };
  }
  return {
    color: variant.color,
    colorName: variant.name,
    quantity
  };
});
export const groupMulticolorSharedItemsInSameBox = items => Object.values(items.reduce((acc, item) => {
  const key = `${item.prodId}_${item.boxItemsId}`;
  const existingItem = acc[key];
  acc[key] = existingItem ? _objectSpread({}, existingItem, {
    quantity: existingItem.quantity + item.quantity,
    variants: [...existingItem.variants, ...item.variants]
  }) : item;
  return acc;
}, {}));
export const getSharedItemInfo = (editedItem, items, storage = OrderStorageTypes.CART) => {
  if (!checkIsSharedItem(editedItem, items)) {
    return {
      isSharedItem: false
    };
  }
  let totalQuantity = editedItem.quantity;
  const isEditedSplitShippedToInventory = checkIsSplitShippedToInventory(editedItem);
  const sharedItemsInfo = [{
    name: isEditedSplitShippedToInventory ? `${TOOLTIP_INDIVIDUAL_ITEM_NAME} (Warehouse)` : TOOLTIP_INDIVIDUAL_ITEM_NAME,
    variantsInfo: getVariantsInfo(editedItem)
  }];
  const sharedItems = items.filter(item => checkIsSharedItem(editedItem, [item]));
  groupMulticolorSharedItemsInSameBox(sharedItems).forEach(sharedItem => {
    var _box$name;
    const box = items.find(it => {
      var _it$prod;
      return it.boxItemsId === sharedItem.boxItemsId && (it === null || it === void 0 ? void 0 : (_it$prod = it.prod) === null || _it$prod === void 0 ? void 0 : _it$prod.designUploadMode) === DesignUploadMode.box;
    });
    totalQuantity += sharedItem.quantity;
    const boxName = `${(_box$name = box === null || box === void 0 ? void 0 : box.name) !== null && _box$name !== void 0 ? _box$name : ''} (Box)`;
    const name = getTooltipItemName(editedItem, sharedItem, boxName);
    sharedItemsInfo.push({
      name,
      variantsInfo: getVariantsInfo(sharedItem)
    });
  });
  return {
    storage,
    totalQuantity,
    sharedItemsInfo,
    isSharedItem: true
  };
};
export const getSetupFeeLabel = isShared => {
  return isShared ? 'Shared Setup Fee:' : 'Setup Fee:';
};
export const getProdVariant = ({
  prodVariants,
  itemVariant,
  itemColor = null
}) => {
  if (isFinite(itemVariant.variantIndex)) {
    return prodVariants[itemVariant.variantIndex];
  }
  return prodVariants.find(variant => variant.color === itemColor);
};
export const handleUpdateInventoryUpsellVariantQuantity = (state, payload) => {
  var _upsellMap$itemId;
  const upsellMap = state.upsellTotalQuantitiesMap;
  const {
    itemId,
    variantIndex,
    sizeIndex = 0,
    quantity
  } = payload;
  let sizeIndexNumber = Number(sizeIndex);
  const variantQuantitiesArray = upsellMap === null || upsellMap === void 0 ? void 0 : (_upsellMap$itemId = upsellMap[itemId]) === null || _upsellMap$itemId === void 0 ? void 0 : _upsellMap$itemId[variantIndex];
  const item = state.items.find(({
    _id
  }) => _id === itemId);
  if (!item || !isFinite(Number(variantQuantitiesArray === null || variantQuantitiesArray === void 0 ? void 0 : variantQuantitiesArray[sizeIndexNumber]))) {
    return state;
  }
  const updatedItemsMap = _objectSpread({}, state.upsellTotalQuantitiesMap);
  const isItemBox = isBox(item);
  const itemsInBox = isItemBox ? getBoxItems(item, state.items) : [];
  const isSticker = checkIsSticker(item === null || item === void 0 ? void 0 : item.prod);
  if (isSticker) {
    const selectedSizeQuantity = variantQuantitiesArray.find(Boolean);
    sizeIndexNumber = variantQuantitiesArray.findIndex(itemSizeQuantity => itemSizeQuantity === selectedSizeQuantity);
  }
  if (itemsInBox.length) {
    itemsInBox.forEach(currentItem => {
      const quantityPerBox = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.quantityPerBox) || 1;
      const itemId = String(currentItem._id);
      const itemVariantsQuantity = updatedItemsMap[itemId];
      if (!itemVariantsQuantity || checkIsApparel(currentItem.prod)) {
        return;
      }
      const newVariantsQuantity = Object.entries(itemVariantsQuantity).reduce((acc, [variantIndex, quantities]) => {
        acc[Number(variantIndex)] = quantities.map(() => quantity * quantityPerBox);
        return acc;
      }, {});
      updatedItemsMap[itemId] = _objectSpread({}, updatedItemsMap[itemId], newVariantsQuantity);
    });
  }
  const newQuantities = [...variantQuantitiesArray];
  newQuantities[sizeIndexNumber] = quantity;
  updatedItemsMap[itemId] = _objectSpread({}, updatedItemsMap[itemId], {
    [variantIndex]: newQuantities
  });
  return _objectSpread({}, state, {
    upsellTotalQuantitiesMap: updatedItemsMap
  });
};
export const resetUpdateInventoryUpsellVariantQuantity = (state, payload) => {
  const {
    itemId,
    quantitiesMap,
    inventoryQuantitiesMap
  } = payload;
  const updatedItemsMap = _objectSpread({}, state.upsellTotalQuantitiesMap);
  const updatedInventoryItemsMap = _objectSpread({}, state.upsellInventoryQuantitiesMap);
  updatedItemsMap[itemId] = quantitiesMap;
  updatedInventoryItemsMap[itemId] = inventoryQuantitiesMap;
  return _objectSpread({}, state, {
    upsellTotalQuantitiesMap: updatedItemsMap,
    upsellInventoryQuantitiesMap: updatedInventoryItemsMap
  });
};
export const initializeUpsellInputData = state => {
  const upsellTotalQuantitiesMap = {};
  const inventoryUpsellSelectedVariantsIndexesMap = {};
  const upsellInventoryQuantitiesMap = {};
  state.items.forEach(item => {
    if (!item._id) {
      return;
    }
    const itemToInventory = checkIsSplitShippedToInventory(item) ? item : getSplitShippedToCustomerCouple({
      toCustomerItem: item,
      allItems: state.items
    });
    item.variants.forEach(({
      variantIndex,
      quantities
    }) => {
      var _itemToInventory$vari;
      const currentUpsellItem = upsellTotalQuantitiesMap[item._id] || {};
      const currentSendToInventoryItem = upsellInventoryQuantitiesMap[item._id] || {};
      const sendToInventoryItemVariant = itemToInventory === null || itemToInventory === void 0 ? void 0 : (_itemToInventory$vari = itemToInventory.variants) === null || _itemToInventory$vari === void 0 ? void 0 : _itemToInventory$vari.find(variant => variant.variantIndex === variantIndex);
      const withoutCoupleQuantities = checkIsSplitShippedToInventory(item) ? quantities : quantities.map(() => 0);
      const sendToInventoryItemQuantities = sendToInventoryItemVariant ? sendToInventoryItemVariant.quantities : withoutCoupleQuantities;
      upsellTotalQuantitiesMap[item._id] = _objectSpread({}, currentUpsellItem, {
        [variantIndex]: quantities.map((quantity, index) => checkIsSplitShippedToInventory(item) ? quantity : quantity + sendToInventoryItemQuantities[index])
      });
      upsellInventoryQuantitiesMap[item._id] = _objectSpread({}, currentSendToInventoryItem, {
        [variantIndex]: sendToInventoryItemQuantities
      });
    });
    const variantsIndexesOfItem = Object.keys(upsellTotalQuantitiesMap[item._id]).map(Number);
    inventoryUpsellSelectedVariantsIndexesMap[item._id] = Math.min(...variantsIndexesOfItem);
  }, {});
  return _objectSpread({}, state, {
    upsellTotalQuantitiesMap,
    inventoryUpsellSelectedVariantsIndexesMap,
    upsellInventoryQuantitiesMap
  });
};
export const getUpsellItemColorQuantity = sizeQuantities => sizeQuantities.reduce((sum, currSizeQuantity) => sum + currSizeQuantity, 0);
export const getUpsellItemOverallUpdatedQuantity = (item, variantsQuantityMap) => {
  if (!(item !== null && item !== void 0 && item._id) || !(variantsQuantityMap !== null && variantsQuantityMap !== void 0 && variantsQuantityMap[item._id])) {
    return 0;
  }
  const itemQuantities = variantsQuantityMap[item._id];
  return Object.values(itemQuantities).reduce((total, sizeQuantities) => {
    return total + getUpsellItemColorQuantity(sizeQuantities);
  }, 0);
};
export const getUpsellToCustomerItemOverallUpdatedQuantity = (item, variantsTotalQuantityMap, variantsInventoryQuantityMap) => {
  const inventoryQuantity = getUpsellItemOverallUpdatedQuantity(item, variantsInventoryQuantityMap);
  const total = getUpsellItemOverallUpdatedQuantity(item, variantsTotalQuantityMap);
  return total - inventoryQuantity;
};
export const absoluteDiscountValueFn = cart => {
  const {
    measurement,
    promocodeDiscount = 0,
    items,
    setupFeeDiscount
  } = cart;
  const unwindedItems = [];
  items.forEach(item => {
    if (item.products) {
      unwindedItems.push(...item.products);
    }
    unwindedItems.push(item);
  });
  return Price.getDiscount(unwindedItems, promocodeDiscount, measurement, setupFeeDiscount);
};