function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-common/utils/price';
import { isItemsInTheSameBox } from 'swag-common/utils/order/item-is-box.util';
import { updateItemQuantity } from './get-upsell-widget-data/calculate-upsell-quantity/update-item-quantity.util';
import { getNextPriceBreakForCartItem } from './get-upsell-widget-data/get-next-price-break-for-cart-item';
export const getUpsellWidgetData = ({
  product,
  quantity,
  associatedProductsQuantity,
  items,
  screenPrice,
  setupFee,
  selectedVariants,
  isUpsellDismissed,
  hideWidget = false,
  isBoxItem,
  isWithRelatedProducts,
  percentToNextPriceBreak,
  isNewPriceAllowedToBeEqualCurrent,
  item,
  showSharedProducts,
  isWithSharedProducts,
  isBoxSpecialProduct,
  boxQuantity,
  nextPriceBreak: propsNextPriceBreak,
  shouldApplyInitialPB,
  isSplitShipped,
  shouldShowSplitShipped = false,
  featureFlags,
  allOrderItems
}) => {
  const isSampleItem = (item === null || item === void 0 ? void 0 : item.isSample) || (item === null || item === void 0 ? void 0 : item.isPrintedSample);
  const isSharedRestricted = isWithSharedProducts && !isSplitShipped;
  const isSplitShippedRestricted = !shouldShowSplitShipped && isSplitShipped;
  const isNotAllowedItemType = isBoxItem || isSampleItem || !showSharedProducts && isSharedRestricted || !isBoxSpecialProduct && isWithRelatedProducts || isSplitShippedRestricted;
  if (isNotAllowedItemType || isUpsellDismissed || hideWidget) {
    return {
      showInWidget: false
    };
  }
  const totalQuantity = associatedProductsQuantity + quantity;
  const {
    nextPriceBreak: nextPriceBreakToUse,
    newQuantity,
    initialQuantity,
    isWithinRange,
    priceBreaks
  } = getNextPriceBreakForCartItem({
    item,
    product,
    sharedItems: items,
    percentToNextPriceBreak,
    quantity,
    nextPriceBreak: propsNextPriceBreak,
    selectedVariants,
    shouldApplyInitialPB,
    boxQuantity,
    featureFlags
  });
  const currentPrice = Price.getTotalForRelatedProducts({
    items
  });
  const currentItemPrice = Price.getItemPriceWithoutSetUpFee({
    price: currentPrice,
    quantity: totalQuantity,
    setupFee
  });
  const totalQuantityPerProduct = nextPriceBreakToUse || totalQuantity;
  if (!nextPriceBreakToUse || !newQuantity) {
    return {
      showInWidget: false,
      currentItemPrice,
      priceBreaks,
      newQuantity: nextPriceBreakToUse,
      totalQuantityPerProduct,
      initialPriceBreak: initialQuantity
    };
  }
  const newItems = items.map(sharedItem => {
    const nonBoxItemQuantity = String(item === null || item === void 0 ? void 0 : item._id) === String(sharedItem._id) ? newQuantity : sharedItem.quantity;
    const quantityToSet = newQuantity && isItemsInTheSameBox({
      currentItem: item,
      item: sharedItem
    }) ? newQuantity * (sharedItem.quantityPerBox || 1) : nonBoxItemQuantity;
    return updateItemQuantity({
      item: _objectSpread({}, sharedItem, {
        prod: product
      }),
      quantity: quantityToSet,
      blendedQuantity: nextPriceBreakToUse,
      screenPrice,
      allOrderItems
    });
  });
  const newPrice = Price.getTotalForRelatedProducts({
    items: newItems
  });
  const newItemPrice = Price.getItemPriceWithoutSetUpFee({
    price: newPrice,
    quantity: nextPriceBreakToUse,
    setupFee
  });
  const newPriceCanBeEqualToCurrent = isNewPriceAllowedToBeEqualCurrent || propsNextPriceBreak;
  const arePricesInvalid = newPriceCanBeEqualToCurrent ? newItemPrice > currentItemPrice : newItemPrice >= currentItemPrice;
  if (isFinite(newItemPrice) && isFinite(currentItemPrice) && arePricesInvalid) {
    return {
      showInWidget: false,
      priceBreaks,
      currentItemPrice,
      newQuantity,
      totalQuantityPerProduct,
      initialPriceBreak: initialQuantity
    };
  }
  return {
    newQuantity,
    initialPriceBreak: initialQuantity,
    newItemPrice,
    currentItemPrice,
    isWithinRange,
    showInWidget: true,
    priceBreaks,
    totalQuantityPerProduct
  };
};