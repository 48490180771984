function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { getDefaultProductSettings, getFirstOrDefaultProductSettings, getItemSelectedProductSettings, getSelectedProductSettingsByEmbellishmentId } from 'swag-common/utils/items/get-item-selected-product-settings.util';
import { BLANK_EMBELLISHMENT_METHOD_NAME, DEFAULT_EMBELLISHMENT_NAME } from 'swag-common/constants/product.constants';
function getItemProdFromProductSettings(product, productSettings) {
  return _objectSpread({}, product, {
    productSettings
  });
}
export function getItemProdByItem(product, item, featureFlags) {
  const productSettings = getItemSelectedProductSettings(product, item, featureFlags);
  if (!productSettings.length) {
    const embellishmentName = item.logos && Object.keys(item.logos) && !item.embellishmentMethod ? DEFAULT_EMBELLISHMENT_NAME : item.embellishmentMethod || BLANK_EMBELLISHMENT_METHOD_NAME;
    throw new Error(`No such embellishment(${embellishmentName}) for product(${item.prodId})`);
  }
  return getItemProdFromProductSettings(product, productSettings);
}
function getItemProdByEmbellishmentId(product, embellishmentMethodId, featureFlags) {
  const productSettings = getSelectedProductSettingsByEmbellishmentId(product, embellishmentMethodId, featureFlags);
  if (!productSettings.length) {
    throw new Error(`Product settings with embellishment method ${embellishmentMethodId} not found`);
  }
  return getItemProdFromProductSettings(product, productSettings);
}
function getItemProdWithDefaultProductSettings(product, featureFlags) {
  const productSettings = getDefaultProductSettings(product, featureFlags);
  return getItemProdFromProductSettings(product, productSettings);
}
function getItemProdWithFirstOrDefaultProductSetting(product, featureFlags) {
  const productSettings = getFirstOrDefaultProductSettings(product, featureFlags);
  return getItemProdFromProductSettings(product, productSettings);
}
export function getItemProd(product, featureFlags, embellishmentMethodId = null) {
  var _product$productSetti;
  if (product.specialProductType) {
    return getItemProdWithFirstOrDefaultProductSetting(product, featureFlags);
  }
  const isNotExistInProductSettings = ((_product$productSetti = product.productSettings) !== null && _product$productSetti !== void 0 ? _product$productSetti : []).every(settings => settings.embellishmentMethodId !== embellishmentMethodId);
  if (isNotExistInProductSettings) {
    return getItemProdWithDefaultProductSettings(product, featureFlags);
  }
  return getItemProdByEmbellishmentId(product, embellishmentMethodId, featureFlags);
}