import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';

// Canadian Zip Code rules https://www.canadapost-postescanada.ca/cpc/en/support/articles/addressing-guidelines/postal-codes.page
// Disided to use all letters for now, because we can't keep the pattern up to date
// pattern that enabled check on Z, W etc letters of zip codes. They are not allowed.
// If you should fix this - just use the pattern below
// https://regex101.com/r/mOrmUL/1
// /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] \d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const ZIP_PATTERNS = {
  [COUNTRY_CODES.US]: /^\d{5,15}$/,
  [COUNTRY_CODES.CA]: /^[A-Z]\d[A-Z] \d[A-Z]\d$/i
};
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const MAX_EMAIL_LENGTH = 254;
export const ONE_LOWERCASE_CHARACTER = 'One lowercase character';
export const ONE_UPPERCASE_CHARACTER = 'One uppercase character';
export const ONE_NUMERIC_CHARACTER = 'One number';
export const ONE_SPECIAL_CHARACTER = 'One special character';
export const MIN_PASSWORD_LENGTH_CHARACTERS = `${MIN_PASSWORD_LENGTH} characters minimum`;
export const MAX_PASSWORD_LENGTH_CHARACTERS = `${MAX_PASSWORD_LENGTH} characters maximum`;
export const PASSWORD_REQUIREMENTS = [ONE_LOWERCASE_CHARACTER, ONE_UPPERCASE_CHARACTER, ONE_NUMERIC_CHARACTER, ONE_SPECIAL_CHARACTER, MIN_PASSWORD_LENGTH_CHARACTERS];
export const AT_LEAST_ONE_LOWERCASE_CHARACTER_REGEXP = /^(?=.*[a-z])/;
export const AT_LEAST_ONE_UPPERCASE_CHARACTER_REGEXP = /^(?=.*[A-Z])/;
export const AT_LEAST_ONE_NUMERIC_CHARACTER_REGEXP = /^(?=.*[0-9])/;
export const AT_LEAST_ONE_SPECIAL_CHARACTER_REGEXP = /^(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;
export const ENTIRE_PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/;