import _sum from "lodash/sum";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dateFormat from 'dateformat';
import { InvoiceType, PaymentMethod } from '@ezetech/swag-presentation-templates/dist/invoices/interfaces';
import { isStatedItem, excludeRejectedAndAbandoned, isCancelledItem, getPaymentMethodByCode } from 'swag-common/utils/order';
import { calculateSetupFeePerItem } from 'swag-common/business-logic';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { checkIsSplitShipped } from 'swag-common/utils/order/is-item-split-shipped';
import { capitalizeFirstWord } from 'swag-common/utils/strings';
import { PREDEFINED_TENANTS } from 'swag-common/constants/main-swag.constants';
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import * as colorsUtils from 'swag-common/utils/colors';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import { getBundleTypeByTenant } from 'swag-common/utils/get-bundle-type-by-tenant';
import { Price } from 'swag-common/utils/price';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { getItemEmbellishmentMethodName } from 'swag-common/utils/items/get-item-embellishment-method-name.util';
import { calculateSampleDeductionDiscount } from 'swag-common/business-logic/order/calculate-sample-deduction-discount.logic';
import { getHeaderInfoForInvoice } from 'swag-common/utils/invoices/get-header-info-for-invoice.util';
import { addDays } from '../date-lib';
import { checkIsSwagOrResellerInventoryOrder } from './check-is-swag-or-reseller-inventory-order.util';
export const formatOrderDataForInvoice = ({
  order,
  invoiceType,
  tenantStoreSettings
}) => {
  const {
    items: allItems,
    promocode,
    promocodeDiscount = 0,
    measurement,
    shipmentCustomerTotal,
    tenant = PREDEFINED_TENANTS.SWAG,
    discountWithoutAdditionals = 0,
    paymentMethod = PaymentMethod.CREDIT_CARD,
    num,
    confirmedAt,
    isTaxExempt,
    IS_TAXFREE,
    shipmentDiscount = 0,
    setupFeeDiscount = 0,
    paymentTerms
  } = order;

  //handle canceled items
  const items = allItems.filter(isStatedItem);
  const itemsPrintInfo = allItems.filter(excludeRejectedAndAbandoned);
  const bundleType = getBundleTypeByTenant(tenant);
  const {
    logo,
    address,
    email: headerEmail,
    phone
  } = getHeaderInfoForInvoice(tenant, tenantStoreSettings);
  const {
    paymentDeadline,
    paymentPeriod,
    checkInfo,
    bankInfo
  } = getFooterInfoForOrderInvoice({
    paymentMethod: paymentMethod,
    confirmedAt: confirmedAt || new Date(),
    tenant,
    tenantStoreSettings
  });
  const taxes = order.taxesPrice || 0;
  const itemsPrice = Price.getTotalWithoutDiscounts(items);
  const sampleDeductionDiscount = calculateSampleDeductionDiscount(items);
  const replacementDiscount = Price.getReplacementDiscount(items);
  const {
    boxes,
    splitShippedItems,
    standaloneItems,
    totalItemsDiscount
  } = formatItemDataForDraftInvoice(itemsPrintInfo);
  const subTotal = itemsPrice - totalItemsDiscount + shipmentCustomerTotal + taxes;
  const grandTotal = subTotal - discountWithoutAdditionals - sampleDeductionDiscount - setupFeeDiscount - shipmentDiscount - replacementDiscount;
  const formattedItems = [...standaloneItems, ...splitShippedItems];
  const promocodeLabel = measurement === PromocodeMeasurementEnum.PERCENTAGES ? `${promocodeDiscount}%` : '';
  const discountLabel = totalItemsDiscount ? 'Reorder' : '';
  const isTaxExemptOrder = Boolean(IS_TAXFREE || isTaxExempt);
  const invoiceNumber = invoiceType === InvoiceType.ORDER ? `INV-${num}` : `QU-${Date.now().toString().substr(-4)}`;
  const invoiceDate = dateFormat(confirmedAt || new Date(), 'mmmm dS, yyyy'); // date when customer requested invoice
  const billTo = getBillToFromOrder(order);
  const shipTo = getShipToFromOrder(order);
  const shipToAdditional = splitShippedItems.length > 0 ? 'Fulfillment Center' : '';
  return _objectSpread({
    bundleType,
    invoiceType,
    //header info
    logoUrl: logo,
    address,
    email: headerEmail,
    phone,
    // address info
    invoiceDate,
    invoiceNumber,
    billTo,
    shipTo,
    shipToAdditional,
    // footer info
    paymentDeadline,
    paymentPeriod,
    checkInfo,
    bankInfo
  }, paymentTerms !== null && paymentTerms !== void 0 && paymentTerms.length ? {
    paymentTerms
  } : {}, {
    // content
    boxes,
    items: formattedItems,
    paymentMethod: paymentMethod,
    orderPrices: {
      itemsTotal: Price.formatPrice(itemsPrice),
      shipping: Price.formatPrice(shipmentCustomerTotal),
      taxes: Price.formatPrice(taxes),
      discount: totalItemsDiscount ? Price.formatPrice(totalItemsDiscount) : '',
      discountLabel,
      subTotal: subTotal > 0 ? Price.formatPrice(subTotal) : Price.formatPrice(0),
      promocodeDeduction: discountWithoutAdditionals ? Price.formatPrice(discountWithoutAdditionals) : '',
      promocodeValue: promocodeLabel,
      promocodeName: promocode || '',
      shippingDeduction: shipmentDiscount ? Price.formatPrice(shipmentDiscount) : '',
      setupFeeDeduction: setupFeeDiscount ? Price.formatPrice(setupFeeDiscount) : '',
      sampleDeduction: sampleDeductionDiscount ? Price.formatPrice(sampleDeductionDiscount) : '',
      replacementDeduction: replacementDiscount ? Price.formatPrice(replacementDiscount) : '',
      isTaxExempt: isTaxExemptOrder,
      total: grandTotal > 0 ? Price.formatPrice(grandTotal) : Price.formatPrice(0)
    }
  });
};
export const formatItemDataForDraftInvoice = items => {
  const boxesMap = {};
  const splitShippedMap = {};
  const standaloneItems = [];
  let totalItemsDiscount = 0;
  items.forEach(item => {
    var _shipmentCharges$find;
    const {
      prod,
      tax = 0,
      asRelatedItemId,
      price,
      quantity,
      blendedQuantity,
      boxItemsId,
      variants,
      shipmentCharges,
      serviceCode,
      setupFeeDeductionDiscount,
      _id,
      prodId,
      isPrintedSample
    } = item;
    const isShared = isSharedItem(item, items);
    const isRelated = isRelatedItem(item, items, true);
    const isSplitShipped = checkIsSplitShipped(item);
    const isBoxItem = isBox(item);
    const isCrossedOut = isCancelledItem(item);
    const productName = (prod === null || prod === void 0 ? void 0 : prod.name) || '';
    const shipping = boxItemsId ? 0 : getItemShippingCost(getDataForGettingItemShippingCost(item, items));
    const shippingOption = shipmentCharges === null || shipmentCharges === void 0 ? void 0 : (_shipmentCharges$find = shipmentCharges.find(c => c.serviceCode === serviceCode)) === null || _shipmentCharges$find === void 0 ? void 0 : _shipmentCharges$find.name.split('[')[0];
    let realSetupFee = calculateSetupFeePerItem({
      item: item,
      allOrderItems: items
    });

    // printed sample setup fee is 0
    if (isPrintedSample) {
      realSetupFee = 0;
    }
    const itemPrice = (price - realSetupFee) / quantity;
    const itemTotal = price + shipping + tax;
    const setupFeeDeductionCoefficient = blendedQuantity ? quantity / blendedQuantity : 1;
    const discounts = isShared || isRelated ? setupFeeDeductionDiscount * setupFeeDeductionCoefficient : setupFeeDeductionDiscount;
    const discountLabel = discounts ? 'Reorder' : '';

    // calculate total items discount from reorder
    if (!isCrossedOut) {
      totalItemsDiscount += discounts;
    }
    const decoration = getItemDecoration(item);
    const colors = variants.map(variant => {
      var _prod$variants$varian, _prod$size, _prod$size$options;
      const colorName = (prod === null || prod === void 0 ? void 0 : (_prod$variants$varian = prod.variants[variant.variantIndex]) === null || _prod$variants$varian === void 0 ? void 0 : _prod$variants$varian.name) || '';
      const totalQuantity = _sum(variant.quantities);
      const total = itemPrice * totalQuantity;
      const sizeQuantities = prod === null || prod === void 0 ? void 0 : (_prod$size = prod.size) === null || _prod$size === void 0 ? void 0 : (_prod$size$options = _prod$size.options) === null || _prod$size$options === void 0 ? void 0 : _prod$size$options.reduce((acc, size, index) => {
        const sizeQuantity = variant.quantities[index];
        if (sizeQuantity) {
          acc.push(`${size}-${sizeQuantity}`);
        }
        return acc;
      }, []);
      const sizeBreakdown = sizeQuantities !== null && sizeQuantities !== void 0 && sizeQuantities.length ? sizeQuantities.join(', ') : 'One size';
      return {
        colorName,
        totalQuantity,
        itemPrice,
        total,
        sizeBreakdown
      };
    });
    const formattedItem = {
      productName,
      decoration,
      colors,
      setupFee: realSetupFee,
      taxes: tax,
      itemTotal,
      shipping,
      shippingOption: (shippingOption === null || shippingOption === void 0 ? void 0 : shippingOption.trim()) || '',
      discounts,
      discountLabel,
      isCrossedOut,
      _id: String(_id),
      prodId,
      asRelatedItemId
    };
    if (boxItemsId) {
      if (isBoxItem) {
        const boxShipping = getItemShippingCost(getDataForGettingItemShippingCost(item, items));
        boxesMap[boxItemsId] = _objectSpread({}, boxesMap[boxItemsId], {
          boxShipping: boxShipping,
          boxName: item.name || '',
          boxShippingOption: 'Economy'
        });
      }
      if (!boxesMap[boxItemsId]) {
        boxesMap[boxItemsId] = {
          boxName: item.name || '',
          products: [],
          boxShipping: 0,
          boxTotal: 0
        };
      }
      boxesMap[boxItemsId].boxTotal = (boxesMap[boxItemsId].boxTotal || 0) + itemTotal - discounts;
      boxesMap[boxItemsId].products = boxesMap[boxItemsId].products ? [...boxesMap[boxItemsId].products, formattedItem] : [formattedItem];
    } else if (isSplitShipped && !boxItemsId && !isCrossedOut) {
      splitShippedMap[asRelatedItemId] = splitShippedMap[asRelatedItemId] ? [...splitShippedMap[asRelatedItemId], formattedItem] : [formattedItem];
    } else {
      standaloneItems.push(formattedItem);
    }
  });
  const formattedBoxes = Object.values(boxesMap).map(item => formatBoxPrices(item, items));
  const formattedSplitShippedItems = Object.values(splitShippedMap).map(combineProducts).map(item => formatItemPrices(item, items));
  const formattedStandaloneItems = standaloneItems.map(item => formatItemPrices(item, items));
  return {
    boxes: formattedBoxes,
    splitShippedItems: formattedSplitShippedItems,
    standaloneItems: formattedStandaloneItems,
    totalItemsDiscount
  };
};
function getItemDecoration(item) {
  const {
    logos,
    texts,
    embellishmentMethod,
    prod
  } = item;
  const currentItemColorData = colorsUtils.getUniqueColorsNumberForAllSides(logos, texts);
  if (!prod || !embellishmentMethod || !logos) {
    return '';
  }
  if (isProductFullDesign(prod)) {
    return 'Full design';
  }
  const colors = Object.entries(currentItemColorData).map(([side, colorsNumber]) => {
    return `${colorsNumber} color ${side}`;
  });
  const embellishmentMethodName = getItemEmbellishmentMethodName(item);
  return `${capitalizeFirstWord(String(embellishmentMethodName))} ${colors.length ? `(${colors.join(', ')})` : ''}`;
}
function combineProducts(products) {
  const combined = {
    productName: products[0].productName,
    decoration: products[0].decoration,
    colors: [],
    setupFee: 0,
    taxes: 0,
    itemTotal: 0,
    shipping: 0,
    shippingOption: products[0].shippingOption,
    discounts: 0,
    discountLabel: products[0].discountLabel,
    isCrossedOut: products[0].isCrossedOut,
    _id: products[0]._id,
    prodId: products[0].prodId,
    asRelatedItemId: products[0].asRelatedItemId
  };
  const colorMap = {};
  products.forEach(product => {
    combined.setupFee += product.setupFee;
    combined.taxes += product.taxes;
    combined.itemTotal += product.itemTotal;
    combined.shipping += product.shipping;
    combined.discounts += product.discounts;
    product.colors.forEach(color => {
      if (!colorMap[color.colorName]) {
        colorMap[color.colorName] = {
          colorName: color.colorName,
          itemPrice: color.itemPrice,
          sizeBreakdown: color.sizeBreakdown,
          totalQuantity: 0,
          total: 0,
          sizeBreakdownMap: {}
        };
      }
      colorMap[color.colorName].totalQuantity += color.totalQuantity;
      colorMap[color.colorName].total += color.total;
      const sizeEntries = color.sizeBreakdown.split(', ');
      sizeEntries.forEach(sizeEntry => {
        const [size, quantity] = sizeEntry.split('-');
        const qty = parseInt(quantity, 10);
        if (colorMap[color.colorName].sizeBreakdownMap[size]) {
          colorMap[color.colorName].sizeBreakdownMap[size] += qty;
        } else {
          colorMap[color.colorName].sizeBreakdownMap[size] = qty;
        }
      });
    });
  });
  combined.colors = Object.values(colorMap).map(color => {
    const {
        sizeBreakdownMap
      } = color,
      rest = _objectWithoutProperties(color, ["sizeBreakdownMap"]);
    if (color.sizeBreakdown === 'One size') {
      return rest;
    }
    const sizeBreakdown = Object.entries(color.sizeBreakdownMap).map(([size, qty]) => `${size}-${qty}`).join(', ');
    return _objectSpread({}, rest, {
      sizeBreakdown
    });
  });
  return combined;
}
function formatColorPrices(color) {
  return _objectSpread({}, color, {
    itemPrice: Price.formatPrice(color.itemPrice),
    total: Price.formatPrice(color.total)
  });
}
function formatItemPrices(item, items) {
  return _objectSpread({}, item, {
    colors: item.colors.map(formatColorPrices),
    setupFee: Price.formatPrice(item.setupFee),
    isSharedItem: isSharedItem(item, items),
    taxes: Price.formatPrice(item.taxes),
    itemTotal: Price.formatPrice(item.itemTotal),
    shipping: item.shipping ? Price.formatPrice(item.shipping) : '',
    discounts: Price.formatPrice(item.discounts)
  });
}
function formatBoxPrices(box, items) {
  return _objectSpread({}, box, {
    products: box.products.map(item => formatItemPrices(item, items)),
    boxShipping: Price.formatPrice(box.boxShipping),
    boxTotal: Price.formatPrice(box.boxTotal)
  });
}
export const getBillToStringFromDetails = (billingDetails, order) => {
  var _billingDetails$addre, _billingDetails$addre2, _billingDetails$addre3, _billingDetails$addre4, _billingDetails$addre5, _billingDetails$addre6;
  if (!billingDetails) {
    if (!order) {
      return [];
    }
    const {
      fnameShipping,
      lnameShipping,
      company
    } = order;
    return [`${company || ''}`, `${fnameShipping} ${lnameShipping}`];
  }
  return [`${billingDetails.name}`, `${(_billingDetails$addre = billingDetails.address) === null || _billingDetails$addre === void 0 ? void 0 : _billingDetails$addre.line1} ${((_billingDetails$addre2 = billingDetails.address) === null || _billingDetails$addre2 === void 0 ? void 0 : _billingDetails$addre2.line2) || ''}`, `${(_billingDetails$addre3 = billingDetails.address) === null || _billingDetails$addre3 === void 0 ? void 0 : _billingDetails$addre3.city}, ${(_billingDetails$addre4 = billingDetails.address) === null || _billingDetails$addre4 === void 0 ? void 0 : _billingDetails$addre4.state} ${(_billingDetails$addre5 = billingDetails.address) === null || _billingDetails$addre5 === void 0 ? void 0 : _billingDetails$addre5.postal_code}, ${(_billingDetails$addre6 = billingDetails.address) === null || _billingDetails$addre6 === void 0 ? void 0 : _billingDetails$addre6.country}`];
};
function getBillToFromOrder(order) {
  var _charges$;
  const {
    charges
  } = order;
  const billingDetails = charges === null || charges === void 0 ? void 0 : (_charges$ = charges[0]) === null || _charges$ === void 0 ? void 0 : _charges$.billing_details;
  return getBillToStringFromDetails(billingDetails, order);
}
function getShipToFromOrder(order) {
  const {
    fnameShipping,
    lnameShipping,
    company,
    address,
    unit,
    city,
    state,
    country,
    zip,
    tenant
  } = order;
  const isInventoryOrder = checkIsSwagOrResellerInventoryOrder(order);
  const isTenantOrder = [String(PREDEFINED_TENANTS.SENDOSO), String(PREDEFINED_TENANTS.POSTAL)].includes(tenant);
  if (isInventoryOrder) {
    if (!isTenantOrder) {
      return ['Fulfillment Center'];
    }
    if (tenant === String(PREDEFINED_TENANTS.SENDOSO)) {
      return ['Sendoso', '6900 E Camelback Rd.', 'Suite 1100', 'Scottsdale, AZ. 85251, US', 'invoices@sendoso.com'];
    }
    if (tenant === String(PREDEFINED_TENANTS.POSTAL)) {
      return ['Postal', '75 South Higuera St.', 'Suite 240.', 'San Luis Obispo, CA 93401, US', 'invoices@postal.com'];
    }
  }
  return [company, `${fnameShipping} ${lnameShipping}`, `${address} ${unit}`, `${city}, ${state} ${zip}, ${country}`];
}
function getFooterInfoForOrderInvoice({
  confirmedAt,
  paymentMethod,
  tenant,
  tenantStoreSettings
}) {
  var _tenantStoreSettings$, _tenantStoreSettings$2, _tenantStoreSettings$3, _tenantStoreSettings$4;
  // no need footer data for CREDIT_CARD
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return {};
  }
  const paymentMethodObj = getPaymentMethodByCode(paymentMethod);
  const paymentDeadline = paymentMethodObj.dutyDaysCount ? addDays(confirmedAt, paymentMethodObj.dutyDaysCount) : undefined;
  const paymentPeriod = paymentMethodObj.dutyDaysCount || 0;
  if (tenant === PREDEFINED_TENANTS.SWAG) {
    return {
      paymentDeadline,
      paymentPeriod,
      checkInfo: {
        makeOut: '“SWAG.COM” Standard Mail',
        mailTo: 'SWAG.COM',
        attn: 'Accounts Receivable',
        address: 'PO BOX 786037 Philadelphia, PA 19178-6037'
      },
      bankInfo: {
        company: 'SWAG.COM, LLC,',
        address: 'Wells Fargo Bank, NA 420 Montgomery St. San Francisco, CA 94104',
        accountNumber: '4123187312',
        routingNumber: '121000248'
      }
    };
  }
  return {
    paymentDeadline,
    paymentPeriod,
    bankInfo: {
      company: String(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : tenantStoreSettings.companyName),
      address: `${tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : (_tenantStoreSettings$ = tenantStoreSettings.invoiceBankDetails) === null || _tenantStoreSettings$ === void 0 ? void 0 : _tenantStoreSettings$.invoiceBankName}, ${tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : (_tenantStoreSettings$2 = tenantStoreSettings.invoiceBankDetails) === null || _tenantStoreSettings$2 === void 0 ? void 0 : _tenantStoreSettings$2.invoiceBankCountry}`,
      accountNumber: String(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : (_tenantStoreSettings$3 = tenantStoreSettings.invoiceBankDetails) === null || _tenantStoreSettings$3 === void 0 ? void 0 : _tenantStoreSettings$3.invoiceBankAccountNumber),
      routingNumber: String(tenantStoreSettings === null || tenantStoreSettings === void 0 ? void 0 : (_tenantStoreSettings$4 = tenantStoreSettings.invoiceBankDetails) === null || _tenantStoreSettings$4 === void 0 ? void 0 : _tenantStoreSettings$4.invoiceRoutingNumber)
    }
  };
}