import _omit from "lodash/omit";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { browserHistory } from 'react-router';
import { OrderApi } from 'swag-client-common/api/order.api';
import { REORDER_WARNING_POPUP } from 'swag-client-common/constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { addItemsToCart } from 'swag-client-common/redux/cart/cart.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { isAvailableSuperSpeedSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { findAndRemoveNotExistsPrintLocations } from 'swag-client-common/utils/cart.util';
import { Price } from 'swag-client-common/utils/price';
import { checkIsApparel } from 'swag-client-common/utils/product.utils';
import { setSuperSpeedProductIdInLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { v1 } from 'uuid';
import ProductApi from '../../api/product.api';
import { orderTokenSelector } from '../cart/cart.selectors';
import { getDefaultProductionTimeIdx } from '../product/product.utils';
import { showCustomerMockups } from '../show-mockups/show-mockups.actions';
import { itemsToReviewPantoneColorsSelector } from './account-orders-other.selectors';
import { shouldTriggerReviewPantoneColorsSelector } from './account-orders.selectors';
import { singleItemInOrderSelector, userOrdersPaginationSelector, userSearchSelector } from './account-orders.selectors';
import { checkIfItemAvailableForReorder, getBoxItemToReorder, getInitialPeriod, getItemsAvailableForReorder, getRealItemsInsideBoxProdIds, isItemExistInCartWithForPromo, mapDateToMilliseconds, prepareItemToReorder, sortOrdersByTime } from './account-orders.utils';
const PREFIX = 'ACCOUNT_ORDERS_';
export const UPDATE_EXPORT_DATE = `${PREFIX}UPDATE_EXPORT_DATE`;
export const updateExportDate = (dateType, dateValue) => ({
  type: UPDATE_EXPORT_DATE,
  payload: {
    dateType,
    dateValue
  }
});
export const ORDERS_FILTERED = `${PREFIX}ORDERS_FILTERED`;
export const LOADING_STARTED = `${PREFIX}LOADING_STARTED`;
export const LOADING_SUCCESS = `${PREFIX}LOADING_SUCCESS`;
export const LOADING_ERROR = `${PREFIX}LOADING_ERROR`;
export const fetchAccountOrders = createAsyncAction({
  transformData: (data = {}, getState) => {
    const state = getState();
    const {
      page,
      perPage
    } = userOrdersPaginationSelector(state);
    const search = userSearchSelector(state);
    return _objectSpread({
      perPage,
      page,
      search
    }, data);
  },
  fn: OrderApi.getAccountOrders,
  preFn: dispatch => {
    dispatch({
      type: LOADING_STARTED
    });
  },
  success: data => ({
    type: LOADING_SUCCESS,
    timePeriod: getInitialPeriod(mapDateToMilliseconds(data.orders)),
    orders: sortOrdersByTime(mapDateToMilliseconds(data.orders)),
    hasNoOrders: data.orders.length === 0,
    pagination: data.pagination
  }),
  postSuccess: dispatch => {
    window.scrollTo({
      top: 0,
      left: 0
    });
    dispatch(showReviewPantoneColorsPopup());
  },
  error: error => ({
    type: LOADING_ERROR,
    error
  })
});
export const REORDER_ITEM_REQUESTED = `${PREFIX}REORDER_ITEM_REQUESTED`;
export const requestReorder = ({
  orderId,
  itemId,
  isSuperSpeed
}) => async (dispatch, getState) => {
  const state = getState();
  const item = singleItemInOrderSelector(state, {
    orderId,
    itemId
  });
  if (isBox(item)) {
    const realItemsInsideBoxProdIds = getRealItemsInsideBoxProdIds(item);
    let productsToReorder = [];
    if (realItemsInsideBoxProdIds.length === 1) {
      const {
        product
      } = await ProductApi.getById({
        id: realItemsInsideBoxProdIds[0]
      });
      productsToReorder = [product];
    } else {
      const {
        products
      } = await ProductApi.getByIds({
        ids: realItemsInsideBoxProdIds
      });
      productsToReorder = realItemsInsideBoxProdIds.map(_id => products.find(item => String(item._id) === String(_id))).filter(Boolean);
    }
    const {
      unavailableItems,
      isReorderAvailable
    } = getItemsAvailableForReorder(productsToReorder);
    if (unavailableItems.length) {
      return dispatch(showPopup({
        popup: REORDER_WARNING_POPUP,
        item,
        isBox: true,
        unavailableItems,
        isReorderAvailable
      }));
    }
    return dispatch(reorderCustomBox(item, {
      reorderId: orderId
    }));
  }
  if (item.isSample) {
    return dispatch(reorderSampleItem({
      item
    }));
  }
  const {
    product
  } = await ProductApi.getById({
    id: item.prodId
  });
  const isItemAvailableForReorder = checkIfItemAvailableForReorder(product);
  if (!isItemAvailableForReorder) {
    return dispatch(showPopup({
      popup: REORDER_WARNING_POPUP,
      item,
      isBox: false,
      unavailableItems: [product]
    }));
  }
  if (isSuperSpeed) {
    var _item$prod;
    setSuperSpeedProductIdInLS((_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod._id);
  }
  findAndRemoveNotExistsPrintLocations({
    item,
    product
  });
  return dispatch(reorderItem({
    item
  }, orderId));
};
export const SHOWING_TRACKING_NUMBERS = `${PREFIX}SHOWING_TRACKING_NUMBERS`;
export const showTrackingNumbers = ({
  ksTrackings,
  orderNumber
}) => ({
  type: SHOWING_TRACKING_NUMBERS,
  ksTrackings,
  orderNumber
});
export const reorderItem = ({
  item
}, orderId) => (dispatch, getState) => {
  const {
    prod: {
      seoSlug
    },
    prodId
  } = item;
  const {
    cart: {
      items,
      draftItems
    },
    accountOrders: {
      orders
    }
  } = getState();
  const {
    promocodeDiscount,
    measurement,
    items: orderItems
  } = orders.find(accountOrder => accountOrder._id === orderId);
  const productLink = seoSlug || prodId;
  const preparedItem = _objectSpread({}, prepareItemToReorder(item), {
    reorderId: orderId
  });
  const allItems = [...items, ...draftItems];
  const promoIsNotAvailable = isItemExistInCartWithForPromo(allItems, preparedItem);
  if (promoIsNotAvailable) {
    preparedItem.readyForPromo = false;
  }
  const itemWithoutSetupFeeField = _omit(item, 'setupFeePerItem');
  const allOrderItems = (items === null || items === void 0 ? void 0 : items.map(i => _omit(i, 'setupFeePerItem'))) || [];
  if (!allOrderItems.find(i => i._id === itemWithoutSetupFeeField._id)) {
    allOrderItems.push(itemWithoutSetupFeeField);
  }
  preparedItem.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount({
    item: itemWithoutSetupFeeField,
    allOrderItems
  });
  if (promocodeDiscount) {
    const discount = measurement === PromocodeMeasurementEnum.ABSOLUTE && Math.round(promocodeDiscount / orderItems.length) || promocodeDiscount;
    preparedItem.price = Price.applyDiscount(preparedItem.price, discount, measurement);
  }
  browserHistory.push(`${APP_ROUTES.PRODUCT}/${productLink}`);
  dispatch({
    type: REORDER_ITEM_REQUESTED,
    payload: {
      item: preparedItem
    }
  });
};
export const reorderSampleItem = ({
  item
}) => (dispatch, getState) => {
  const orderToken = orderTokenSelector(getState());
  const preparedItem = _objectSpread({}, prepareItemToReorder(item), {
    readyForPromo: false
  });
  dispatch(addItemsToCart({
    items: [preparedItem],
    orderToken: orderToken || 'null'
  }));
  browserHistory.push(APP_ROUTES.CART);
};
export const reorderCustomBox = (item, {
  reorderId
}) => async (dispatch, getState) => {
  var _orderItem$prod;
  const {
      products
    } = item,
    orderItem = _objectWithoutProperties(item, ["products"]);
  const isSomeSuperSpeedItems = products === null || products === void 0 ? void 0 : products.some(item => isSuperSpeedItem(item));
  if (isSomeSuperSpeedItems) {
    await dispatch(getSuperSpeedSettings());
  }
  const boxItems = [];
  const state = getState();
  const orderToken = orderTokenSelector(state);
  const isSuperSpeedAvailable = isAvailableSuperSpeedSelector(state);
  if (products && products.length) {
    const productsWithUpdatedQuantity = products.map(product => {
      var _prod$productSettings, _prod$productSettings2;
      const {
        quantity,
        quantityPerBox,
        variants,
        prod
      } = product;
      if (prod && checkIsApparel(prod)) {
        return product;
      }
      const {
        quantity: quantityOfBoxes
      } = orderItem;
      const updatedQuantity = quantityPerBox ? quantityOfBoxes * quantityPerBox : quantity;
      const updatedVariants = variants.map(item => _objectSpread({}, item, {
        quantities: item.quantities.map(q => q === 0 ? q : updatedQuantity)
      }));
      const prodTime = !isSuperSpeedAvailable && isSuperSpeedItem(product) ? getDefaultProductionTimeIdx(prod === null || prod === void 0 ? void 0 : (_prod$productSettings = prod.productSettings) === null || _prod$productSettings === void 0 ? void 0 : (_prod$productSettings2 = _prod$productSettings[0]) === null || _prod$productSettings2 === void 0 ? void 0 : _prod$productSettings2.productionTimeList) : product.prodTime;
      return _objectSpread({}, product, {
        prodTime,
        quantity: updatedQuantity,
        variants: updatedVariants,
        asRelatedItemId: product.asRelatedItemId || v1()
      });
    });
    boxItems.push(...productsWithUpdatedQuantity);
  }
  const itemsToReorder = [_objectSpread({}, orderItem, {
    name: orderItem.name || ((_orderItem$prod = orderItem.prod) === null || _orderItem$prod === void 0 ? void 0 : _orderItem$prod.name),
    isBoxNotecardAbsent: !boxItems.some(item => {
      var _item$prod2;
      return (item === null || item === void 0 ? void 0 : (_item$prod2 = item.prod) === null || _item$prod2 === void 0 ? void 0 : _item$prod2.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
    }),
    asRelatedItemId: orderItem.asRelatedItemId || v1()
  }), ...boxItems];
  const items = itemsToReorder.map(getBoxItemToReorder({
    boxItemsId: null,
    allOrderItems: itemsToReorder,
    options: {
      reorderId
    }
  }));
  dispatch(addItemsToCart({
    items,
    orderToken: orderToken || 'null',
    isReorderBoxFlow: true
  }));
  if (isSomeSuperSpeedItems && !isSuperSpeedAvailable) {
    dispatch(showNotification({
      hideIn: 6000,
      text: 'The Super Speed option is no longer available on our site. We changed production time to standard for all super speed items inside your box.'
    }));
  }
};
export const showItemImage = (orderId, itemId) => (dispatch, getState) => {
  const item = singleItemInOrderSelector(getState(), {
    orderId,
    itemId
  });
  dispatch(showCustomerMockups(item));
};
export const RESET_ORDERS = `${PREFIX}RESET_ORDERS`;
export const resetOrders = () => ({
  type: RESET_ORDERS
});
export const SEARCH_CHANGE = `${PREFIX}SEARCH_CHANGE`;
export const changeSearch = value => dispatch => {
  dispatch({
    type: SEARCH_CHANGE,
    payload: {
      search: value
    }
  });
};
export const showReviewPantoneColorsPopup = () => (dispatch, getState) => {
  const state = getState();
  const itemsToReviewPantoneColors = itemsToReviewPantoneColorsSelector(state);
  const shouldTriggerReviewPantoneColors = shouldTriggerReviewPantoneColorsSelector(state);
  if (itemsToReviewPantoneColors.length && shouldTriggerReviewPantoneColors) {
    const {
      itemId,
      orderId
    } = itemsToReviewPantoneColors[0];
    dispatch(showPopup({
      popup: 'confirmItemPantones',
      itemId,
      orderId,
      type: 'account'
    }));
  }
};
export const RESET_APPROVE_PANTONE_COLORS_ITEM = `${PREFIX}RESET_APPROVE_PANTONE_COLORS_ITEM`;
export const resetApprovePantoneColorsItem = itemId => ({
  type: RESET_APPROVE_PANTONE_COLORS_ITEM,
  payload: itemId
});