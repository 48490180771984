import { createApi } from 'swag-client-common/libs/rapi';
import { getShipoutShutdownSettings } from './settings-urls.api';
const baseCustomStoreUrl = '/custom-store-api/v1/settings';
const baseUrl = '/api/v1/settings';
export const SettingsApi = createApi({
  getSettings: {
    url: `${baseCustomStoreUrl}`,
    method: 'get'
  },
  getSettingsHistory: {
    url: `${baseCustomStoreUrl}/history`,
    method: 'get'
  },
  updateTurnaroundTime: {
    url: `${baseCustomStoreUrl}/turnaround-time`,
    method: 'put'
  },
  getSuperSpeed: {
    url: `${baseUrl}/super-speed`,
    method: 'get'
  },
  updateSuperSpeed: {
    url: `${baseUrl}/super-speed`,
    method: 'post'
  },
  replacementPriceRange: {
    url: `${baseUrl}/replacement-price-range`,
    method: 'get'
  },
  updateDefaultWarehouse: {
    url: `${baseCustomStoreUrl}/default-warehouse`,
    method: 'put'
  },
  updateShippingMargin: {
    url: `${baseCustomStoreUrl}/shipping-margin`,
    method: 'put'
  },
  updateSpaceShippingMargin: {
    url: `${baseCustomStoreUrl}/space-shipping-margin`,
    method: 'put'
  },
  getHubspotCalendarLinks: {
    url: `${baseUrl}/hubspot-calendar-links`,
    method: 'get'
  },
  getShipoutShutdownSettings: {
    url: getShipoutShutdownSettings,
    method: 'get'
  }
});