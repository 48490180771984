export const getQuantityHeaderKeys = header => header.reduce((quantityKeys, column) => {
  const {
    title,
    original
  } = column;
  return title.toLowerCase().includes('quantity') ? [...quantityKeys, original] : quantityKeys;
}, []);
export const getCountryHeaderKeys = header => header.reduce((stateKeys, column) => {
  const {
    title,
    original
  } = column;
  return title.toLowerCase().includes('country') ? [...stateKeys, original] : stateKeys;
}, []);