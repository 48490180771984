function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isProductFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { Price } from 'swag-common/utils/price';
import { calculateSetupFeePerItem } from '../calculate-setup-fee-value.logic';
import { getOrderItemImage } from './get-order-item-image.logic';
import { sortBoxContentInSwagItems } from './get-order-of-box-content-items.logic';
import getSetupFeeDeductionDiscount from './get-setup-fee-deduction-discount.logic';
export function groupInitialProductsWithOrderBoxItems(baseProducts, boxItems, isReorder, allItems) {
  const boxContentWithCorrectOrder = sortBoxContentInSwagItems(boxItems || []);
  return boxContentWithCorrectOrder.map(item => {
    var _variants$;
    const {
      isSample,
      isPrintedSample,
      customerMockups,
      sizeInd,
      setupFeeDeductionDiscount,
      price,
      quantity,
      variantInd,
      variants
    } = item;
    const product = baseProducts.find(p => p._id === item.prodId);
    const {
      url
    } = getOrderItemImage({
      product,
      customerMockups,
      variantInd: variantInd || ((_variants$ = variants[0]) === null || _variants$ === void 0 ? void 0 : _variants$.variantIndex),
      sizeInd
    });
    const hasRelatedItems = allItems.filter(innerItem => innerItem.asRelatedItemId === item.asRelatedItemId).length > 1;
    const isSharedOrRelatedItem = isSharedItem(item, boxItems) || hasRelatedItems;
    let setupFeeForSingleProduct = isSample || isPrintedSample ? 0 : calculateSetupFeePerItem({
      item,
      allOrderItems: allItems
    });
    if (setupFeeDeductionDiscount && isReorder) {
      const singleItemDeduction = getSetupFeeDeductionDiscount({
        item,
        allOrderItems: allItems
      });
      const delta = setupFeeForSingleProduct - singleItemDeduction;
      setupFeeForSingleProduct = delta > 0 ? delta : 0;
    }
    const screenPrice = Price.getScreenPriceForRelatedProduct(setupFeeForSingleProduct, item.blendedQuantity || item.quantity);
    const deductionAmount = Price.getItemPriceDeductionAmountFromSetupFeeDeduction({
      setupFeeDeductionDiscount,
      blendedQuantity: item.blendedQuantity,
      quantity
    });
    const pricePerPiece = Price.getPricePerItem({
      assemblyFee: item.prod.assemblyFee,
      quantity,
      screenPrice,
      totalPriceForItems: price - deductionAmount,
      item,
      allOrderItems: allItems
    });
    return _objectSpread({}, item, {
      // @todo. auto generated. fix this error
      // @ts-ignore
      name: product.name,
      image: url,
      setupFee: setupFeeForSingleProduct,
      pricePerPiece,
      itemFullDesign: isProductFullDesign(product),
      isSharedOrRelatedItem,
      isRelatedItem: isRelatedItem(item, allItems, true)
    });
  });
}