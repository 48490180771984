import _sum2 from "lodash/sum";
import _cloneDeep2 from "lodash/cloneDeep";
import _intersection from "lodash/intersection";
import _omit2 from "lodash/omit";
import _isEqual from "lodash/isEqual";
import _isFinite from "lodash/isFinite";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { checkIsApparel } from 'swag-client-common/utils/product.utils';
import { getSharedItemsWithItemItself } from 'swag-common/business-logic/items/get-shared-items-with-item-itself.logic';
import { MAX_QUANTITY_TO_BUY } from 'swag-common/constants/product.constants';
import { v1 as uuidV1 } from 'uuid';
import { getSplitShippedItems } from 'swag-common/business-logic/items/get-split-shipped-items';
import { checkIsSplitShippedToCustomer, checkIsSplitShippedToInventory } from 'swag-common/utils/order/is-item-split-shipped';
import { getBoxItems, getSharedItemsQuantityPerBox, getTotalQuantityForSharedItems, isBox, isItemBoxSpecialCrinklePaper, isItemBoxSpecialProduct, isItemBoxWithoutSpecialTypes } from 'swag-common/utils/order/item-is-box.util';
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { isResellerAdminModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { SPLIT_SHIPPED_ITEM_TYPE } from 'swag-common/constants/item-sources';
import { INVENTORY_UPSELL_STATUSES } from 'swag-common/constants/order-statuses.constants';
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { isInBox } from 'swag-common/utils/order/item-is-in-box.util';
import { mergePriceBreaks } from '../../utils/get-upsell-widget-data/calculate-upsell-quantity/merge-price-breaks';
import { getSplitShippedCouple, getSplitShippedToCustomerCouple } from '../../utils/get-upsell-widget-data/check-is-split-shipped-item-to-customer-has-inventory-couple';
import { getInventoryUpsellPresentationData } from '../../utils/get-upsell-widget-data/get-inventory-upsell-presentation-data.util';
import { getNextPriceBreakForCartItem } from '../../utils/get-upsell-widget-data/get-next-price-break-for-cart-item';
import { getUpsellBoxPrice } from '../../utils/get-upsell-widget-data/get-upsell-box-price.util';
import { getUpsellWidgetDataForCartItem } from '../../utils/get-upsell-widget-data/get-upsell-filter-data-for-cart-item.util';
import { subtractUpsellQuantities } from '../../utils/get-upsell-widget-data/subtract-upsell-quantities.util';
import { cartItemsSelector, hasInventoryUnderApprovalFlowSelector, selectedDistributeOptionSelector, selectedInventoryIdSelector } from '../inventory/inventory.selectors';
import { isOrderApprovalFlowSelector } from '../user/user.selectors';
import { cartRootSelector, deliverToFulfillmentCenterSelector, isDeliveryOptionWasChosenSelector, isPreBuiltCartSelector, itemSelector, orderItemImageSelector, propsItemSelector, stockLevelsByProductIdAndVariantSkuSelector, notSplitSetupFeeSelector } from './cart.selectors';
import { getAllRelatedItems, getAllSharedItems, getUpsellItemColorQuantity, getUpsellItemOverallUpdatedQuantity, getUpsellToCustomerItemOverallUpdatedQuantity } from './cart.utils';
export const shouldShowEditWarehouseQuantityWidget = createSelector(deliverToFulfillmentCenterSelector, deliverToFulfillmentCenterSelected => {
  const isCustomerDelivery = !deliverToFulfillmentCenterSelected;
  return isCustomerDelivery;
});
export const isQuantityUpdatedOnServerSelector = createSelector(propsItemSelector, item => Boolean(item.inventoryUpsellStatus));
export const isUpsellAppliedOnServerSelector = createSelector(propsItemSelector, item => item.inventoryUpsellStatus === INVENTORY_UPSELL_STATUSES.APPLIED);
export const upsellItemDataSelector = createSelector(propsItemSelector, ({
  upsellData
}) => upsellData);
const relatedItemsOfCardItemSelector = createSelector(cartItemsSelector, itemSelector, getAllRelatedItems);
export const sharedItemsOfCardItemWithUpdatedQuantitySelector = createSelector((state, props) => ({
  state,
  props
}), cartItemsSelector, itemSelector, ({
  state,
  props
}, allItems, currentItem) => {
  const isBoxSpecialProduct = isItemBoxSpecialProduct(currentItem);
  const itemsWithUpdatesQuantity = allItems.map(item => _objectSpread({}, item, {
    initialQuantity: item.initialQuantity || item.quantity,
    quantity: inventoryUpsellItemByPropsUpdatedQuantitySelector(state, _objectSpread({}, props, {
      item
    }))
  }));
  let sharedItems = getAllSharedItems(itemsWithUpdatesQuantity, currentItem);
  // if it's special box product priceBreak calculates only for items which inside the box
  if (isBoxSpecialProduct) {
    sharedItems = currentItem ? [currentItem] : [];
  }
  return sharedItems;
});
export const upsellDataForCartItems = createSelector(cartItemsSelector, state => state, (cartItems, state) => {
  return cartItems.map(cartItem => _objectSpread({}, cartItem, {
    initialQuantity: cartItem.initialQuantity || cartItem.quantity,
    upsellData: upsellWidgetDataForCardSelector(state, {
      item: cartItem
    })
  }));
});
export const upsellProductsSelector = createSelector(upsellDataForCartItems, items => {
  const allSplitShippedItems = getSplitShippedItems(items);
  const splitShippedItemsIds = allSplitShippedItems.map(item => item === null || item === void 0 ? void 0 : item._id);
  const mergedSplitShippedItems = allSplitShippedItems.reduce((acc, item, _, allItems) => {
    if (checkIsSplitShippedToCustomer(item) || !item.splitShippedType) {
      const couple = getSplitShippedToCustomerCouple({
        toCustomerItem: item,
        allItems
      });
      const quantity = couple ? item.quantity + couple.quantity : item.quantity;
      const toInventoryQuantity = couple ? couple.quantity : 0;
      const toCustomerQuantity = item.quantity;
      const inventoryUpsellStatus = item.inventoryUpsellStatus || (couple === null || couple === void 0 ? void 0 : couple.inventoryUpsellStatus) || null;
      acc.push(_objectSpread({}, item, {
        quantity,
        toInventoryQuantity,
        toCustomerQuantity
      }, {
        inventoryUpsellStatus
      }));
    }
    return acc;
  }, []);
  const notSplitShippedItems = items.filter(({
    _id
  }) => !splitShippedItemsIds.some(id => id === _id)).map(item => _objectSpread({}, item, {
    toInventoryQuantity: checkIsSplitShippedToInventory(item) ? item.quantity : 0,
    toCustomerQuantity: checkIsSplitShippedToInventory(item) ? 0 : item.quantity
  }));
  return [...mergedSplitShippedItems, ...notSplitShippedItems].filter(item => {
    const isUpdated = Boolean(item.inventoryUpsellStatus);
    const isSuperSpeedShow = !isSuperSpeedItem(item) || isSuperSpeedItem(item) && isUpdated;
    return (isUpdated || item.upsellData.showInWidget) && !isInBox(item) && isSuperSpeedShow;
  }).sort((prev, next) => {
    const {
      quantity: prevQuantity,
      initialQuantity: prevInitialQuantity,
      upsellData: {
        newQuantity: prevNewQuantity,
        initialPriceBreak: prevInitialPriceBreak
      }
    } = prev;
    const {
      quantity: nextQuantity,
      initialQuantity: nextInitialQuantity,
      upsellData: {
        newQuantity: nextNewQuantity,
        initialPriceBreak: nextInitialPriceBreak
      }
    } = next;

    // use initial values to avoid shuffle after edit
    const prevProximity = prevInitialQuantity && prevInitialPriceBreak ? Math.round((1 - prevInitialQuantity / prevInitialPriceBreak) * 100) : Math.round((1 - prevQuantity / (prevNewQuantity || 0)) * 100);
    const nextProximity = nextInitialQuantity && nextInitialPriceBreak ? Math.round((1 - nextInitialQuantity / nextInitialPriceBreak) * 100) : Math.round((1 - nextQuantity / (nextNewQuantity || 0)) * 100);
    return prevProximity < nextProximity ? -1 : 1;
  });
});
const haveUpsellProductsWithinRangeSelector = createSelector(upsellProductsSelector, products => Boolean(products.filter(({
  upsellData
}) => upsellData.isWithinRange).length));
const haveAvailableUpsellProductsSelector = createSelector(upsellProductsSelector, products => Boolean(products.filter(({
  upsellData
}) => upsellData.showInWidget).length));
export const upsellWidgetIsVisibleSelector = createSelector(cartRootSelector, state => state.showUpsellWidget);
export const hasAnyItemAppliedUpsellSelector = createSelector(cartItemsSelector, cartItems => {
  return cartItems.some(({
    inventoryUpsellStatus
  }) => Boolean(inventoryUpsellStatus));
});
export const hasAnyItemAppliedCustomerUpsellSelector = createSelector(cartItemsSelector, cartItems => {
  return cartItems.some(checkIsSplitShippedToInventory);
});
export const shouldShowInventoryUpsellWidgetSelector = createSelector(deliverToFulfillmentCenterSelector, selectedDistributeOptionSelector, haveUpsellProductsWithinRangeSelector, upsellWidgetIsVisibleSelector, hasAnyItemAppliedUpsellSelector, isResellerAdminModeSelector, (deliverToFulfillmentCenterSelected, option, haveUpsellProductsWithinRange, upsellWidgetIsVisible, hasAnyItemAppliedInventoryUpsell, isUpsellDisabledForResellerModesSelector) => {
  if (isUpsellDisabledForResellerModesSelector) {
    return false;
  }
  const isUpsellActive = hasAnyItemAppliedInventoryUpsell || haveUpsellProductsWithinRange && upsellWidgetIsVisible;
  return deliverToFulfillmentCenterSelected && option !== null && isUpsellActive;
});
export const shouldShowUpsellWidgetForCustomerDeliverySelector = createSelector(deliverToFulfillmentCenterSelector, haveAvailableUpsellProductsSelector, isDeliveryOptionWasChosenSelector, hasAnyItemAppliedUpsellSelector, isOrderApprovalFlowSelector, hasInventoryUnderApprovalFlowSelector, isPreBuiltCartSelector, isResellerAdminModeSelector, (deliverToFulfillmentCenterSelected, haveAvailableUpsellProducts, isDeliveryOptionWasChosen, hasAnyItemAppliedUpsell, isUserUnderApprovalFlow, hasInventoryUnderApprovalFlow, isPreBuiltCart, isUpsellDisabledForResellerModes) => {
  const isUnderApproval = isUserUnderApprovalFlow || hasInventoryUnderApprovalFlow;
  if (isUnderApproval) {
    return false;
  }
  const isCustomerDelivery = !deliverToFulfillmentCenterSelected;
  const isCustomerUpsellActive = hasAnyItemAppliedUpsell || haveAvailableUpsellProducts;
  return isDeliveryOptionWasChosen && isCustomerDelivery && isCustomerUpsellActive && !isPreBuiltCart && !isUpsellDisabledForResellerModes;
});
export const inventoryUpsellSelectedItemIdSelector = createSelector(cartRootSelector, state => state.inventoryUpsellSelectedItemIdForEdit);
export const inventoryUpsellEditQuantityModeSelector = createSelector(cartRootSelector, state => state.inventoryUpsellEditMode);
export const inventoryUpsellSelectedItemForEditSelector = createSelector(inventoryUpsellSelectedItemIdSelector, upsellProductsSelector, (selectedItemIdForEdit, items) => {
  return items.find(({
    _id
  }) => _id === selectedItemIdForEdit) || null;
});
export const inventoryUpsellGetSplitShippedForSelectedItemForEditSelector = createSelector(cartItemsSelector, inventoryUpsellSelectedItemForEditSelector, (allItems, toCustomerItem) => {
  return toCustomerItem ? getSplitShippedToCustomerCouple({
    toCustomerItem,
    allItems
  }) : null;
});
export const itemProdVariantDataSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, item => {
  var _item$prod;
  if (!(item !== null && item !== void 0 && (_item$prod = item.prod) !== null && _item$prod !== void 0 && _item$prod.variants.length)) {
    return [];
  }
  return item.variants.reduce((acc, {
    variantIndex
  }) => {
    var _item$prod2, _item$prod2$variants;
    if (!(item !== null && item !== void 0 && (_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && (_item$prod2$variants = _item$prod2.variants) !== null && _item$prod2$variants !== void 0 && _item$prod2$variants[variantIndex])) {
      return acc;
    }
    acc.push(_objectSpread({}, item.prod.variants[variantIndex], {
      variantIndex
    }));
    return acc;
  }, []);
});
export const inventoryUpsellSelectedVariantIndexesSelector = createSelector(cartRootSelector, ({
  inventoryUpsellSelectedVariantsIndexesMap
}) => {
  return inventoryUpsellSelectedVariantsIndexesMap;
});
export const upsellTotalVariantsQuantityMapSelector = createSelector(cartRootSelector, ({
  upsellTotalQuantitiesMap
}) => {
  return upsellTotalQuantitiesMap;
});
export const upsellInventoryQuantitiesMapSelector = createSelector(cartRootSelector, ({
  upsellInventoryQuantitiesMap
}) => upsellInventoryQuantitiesMap);
export const upsellInventoryNonZeroQuantitiesMapSelector = createSelector(upsellInventoryQuantitiesMapSelector, upsellInventoryQuantitiesMap => {
  // removing all items if all variants has 0 quantities
  const nonZeroInventoryItems = {};
  Object.keys(upsellInventoryQuantitiesMap).forEach(itemId => {
    const item = upsellInventoryQuantitiesMap[itemId];
    const hasNonZeroQuantity = Object.keys(item).some(variant => {
      return item[variant].some(quantity => quantity > 0);
    });
    if (hasNonZeroQuantity) {
      nonZeroInventoryItems[itemId] = item;
    }
  });
  return nonZeroInventoryItems;
});
export const inventoryUpsellSelectedItemVariantIndexSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, inventoryUpsellSelectedVariantIndexesSelector, (item, selectedVariantsIndexes) => {
  if (item !== null && item !== void 0 && item._id && _isFinite(selectedVariantsIndexes[item._id])) {
    return selectedVariantsIndexes[item._id];
  }
  return Math.min(...Object.values(selectedVariantsIndexes));
});
export const upsellInventoryQuantitiesInputSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, upsellInventoryQuantitiesMapSelector, (item, quantitiesMap) => {
  if (!(item !== null && item !== void 0 && item._id) || !(quantitiesMap !== null && quantitiesMap !== void 0 && quantitiesMap[item._id])) {
    return {};
  }
  return quantitiesMap[item._id];
});
export const upsellTotalItemQuantitiesInputSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, upsellTotalVariantsQuantityMapSelector, (item, quantitiesMap) => {
  if (!(item !== null && item !== void 0 && item._id) || !(quantitiesMap !== null && quantitiesMap !== void 0 && quantitiesMap[item._id])) {
    return {};
  }
  return quantitiesMap[item._id];
});
export const upsellTotalVariantQuantitiesInput = createSelector(upsellTotalItemQuantitiesInputSelector, inventoryUpsellSelectedItemVariantIndexSelector, (quantitiesMap, variantIndex) => {
  if (!_isFinite(variantIndex)) {
    return [];
  }
  return (quantitiesMap === null || quantitiesMap === void 0 ? void 0 : quantitiesMap[variantIndex]) || [];
});
export const upsellInventoryQuantitiesInput = createSelector(upsellInventoryQuantitiesInputSelector, inventoryUpsellSelectedItemVariantIndexSelector, (quantitiesMap, variantIndex) => {
  if (!_isFinite(variantIndex)) {
    return [];
  }
  return (quantitiesMap === null || quantitiesMap === void 0 ? void 0 : quantitiesMap[variantIndex]) || [];
});
export const inventoryUpsellActiveVariantSelector = createSelector(itemProdVariantDataSelector, inventoryUpsellSelectedItemVariantIndexSelector, (variants, index) => {
  return variants.find(({
    variantIndex
  }) => variantIndex === index) || variants[0];
});
export const inventoryUpsellActiveVariantImageSelector = createSelector(inventoryUpsellActiveVariantSelector, orderItemImageSelector, (activeVariant, itemImage) => {
  var _activeVariant$images, _activeVariant$images2, _activeVariant$images3;
  return (activeVariant === null || activeVariant === void 0 ? void 0 : (_activeVariant$images = activeVariant.images) === null || _activeVariant$images === void 0 ? void 0 : (_activeVariant$images2 = _activeVariant$images.front) === null || _activeVariant$images2 === void 0 ? void 0 : (_activeVariant$images3 = _activeVariant$images2[0]) === null || _activeVariant$images3 === void 0 ? void 0 : _activeVariant$images3.url) || itemImage;
});
export const upsellTotalItemOverallUpdatedQuantitySelector = createSelector(inventoryUpsellSelectedItemForEditSelector, upsellTotalVariantsQuantityMapSelector, (item, quantities) => {
  return getUpsellItemOverallUpdatedQuantity(item, quantities);
});
export const inventoryUpsellItemAllColorsUpdatedQuantitySelector = createSelector(upsellTotalVariantQuantitiesInput, variantQuantities => getUpsellItemColorQuantity(variantQuantities));
export const inventoryUpsellItemInCartColorQuantitySelector = createSelector(inventoryUpsellSelectedItemForEditSelector, inventoryUpsellSelectedItemVariantIndexSelector, inventoryUpsellGetSplitShippedForSelectedItemForEditSelector, (editedItem, selectedVariantIndex, splitShippedItem) => {
  const variant = editedItem === null || editedItem === void 0 ? void 0 : editedItem.variants.find(({
    variantIndex
  }) => variantIndex === selectedVariantIndex);
  if (!variant) {
    return 0;
  }
  const splitShippedVariant = splitShippedItem === null || splitShippedItem === void 0 ? void 0 : splitShippedItem.variants.find(({
    variantIndex
  }) => variantIndex === selectedVariantIndex);
  const splitShippedItemQuantities = splitShippedVariant ? getUpsellItemColorQuantity(splitShippedVariant.quantities) : 0;
  return getUpsellItemColorQuantity(variant.quantities) + splitShippedItemQuantities;
});
export const inventoryUpsellItemByPropsUpdatedQuantitySelector = createSelector((_, props) => props.isEditMode, itemSelector, cartItemsSelector, upsellTotalVariantsQuantityMapSelector, (isEditMode, item, items, editedTotalQuantitiesMap) => {
  const isSplitShippedToCustomer = checkIsSplitShippedToCustomer(item);
  if (!isEditMode && isSplitShippedToCustomer) {
    const coupleToInventory = getSplitShippedToCustomerCouple({
      toCustomerItem: item,
      allItems: items
    });
    if (coupleToInventory) {
      return item.quantity + coupleToInventory.quantity;
    }
  }
  return isEditMode ? getUpsellItemOverallUpdatedQuantity(item, editedTotalQuantitiesMap) : item.quantity;
});
export const upsellItemUpdatedQuantitySelector = createSelector((_, props) => props.isEditMode, itemSelector, upsellTotalVariantsQuantityMapSelector, upsellInventoryQuantitiesMapSelector, (isEditMode, item, editedTotalQuantitiesMap, upsellInventoryQuantitiesMap) => {
  const isSplitShippedToInventory = checkIsSplitShippedToInventory(item);
  if (isEditMode) {
    return isSplitShippedToInventory ? getUpsellItemOverallUpdatedQuantity(item, upsellInventoryQuantitiesMap) : getUpsellToCustomerItemOverallUpdatedQuantity(item, editedTotalQuantitiesMap, upsellInventoryQuantitiesMap);
  }
  return item.quantity;
});
export const inventoryUpsellItemWithoutSplitShippedByPropsUpdatedQuantitySelector = createSelector((_, props) => props.isEditMode, itemSelector, upsellTotalVariantsQuantityMapSelector, (isEditMode, item, editedTotalQuantitiesMap) => {
  return isEditMode ? getUpsellItemOverallUpdatedQuantity(item, editedTotalQuantitiesMap) : item.quantity;
});
export const inventoryUpsellItemSplitShippedPartByPropsUpdatedQuantitySelector = createSelector((_, props) => props.isEditMode, itemSelector, upsellInventoryQuantitiesMapSelector, (isEditMode, item, editedInventoryQuantitiesMap) => {
  return isEditMode ? getUpsellItemOverallUpdatedQuantity(item, editedInventoryQuantitiesMap) : item.quantity;
});
export const upsellWasQuantityUpdatedSelector = createSelector(propsItemSelector, upsellTotalVariantsQuantityMapSelector, upsellInventoryQuantitiesMapSelector, (item, editedQuantitiesMap, inventoryQuantitiesMap) => {
  const updatedQuantity = getUpsellItemOverallUpdatedQuantity(item, editedQuantitiesMap);
  const inventoryQuantity = getUpsellItemOverallUpdatedQuantity(item, inventoryQuantitiesMap);
  return !!inventoryQuantity || editedQuantitiesMap[item._id] && item.quantity !== updatedQuantity;
});
export const showViewColorsSelector = createSelector(propsItemSelector, item => !isFullDesign(item.prod) && !isBox(item));
export const inventoryUpsellWereVariantQuantitiesUpdatedSelector = createSelector(propsItemSelector, upsellTotalVariantsQuantityMapSelector, upsellInventoryQuantitiesMapSelector, (item, editedQuantitiesMap, inventoryQuantitiesMap) => {
  if (!(item !== null && item !== void 0 && item._id) || !editedQuantitiesMap[item._id]) {
    return false;
  }
  const shouldUseSubtractMap = checkIsSplitShippedToCustomer(item) || !item.splitShippedType;
  if (shouldUseSubtractMap && inventoryQuantitiesMap !== null && inventoryQuantitiesMap !== void 0 && inventoryQuantitiesMap[item._id]) {
    const subtractedMap = subtractUpsellQuantities(editedQuantitiesMap[item._id], inventoryQuantitiesMap[item._id]);
    const variantQuantitiesArray = Object.entries(subtractedMap).map(([variantIndex, quantities]) => ({
      variantIndex: Number(variantIndex),
      quantities
    }));
    return !_isEqual(variantQuantitiesArray, item.variants);
  }
  const variantQuantitiesArray = Object.entries(editedQuantitiesMap[item._id]).map(([variantIndex, quantities]) => ({
    variantIndex: Number(variantIndex),
    quantities
  }));
  return !_isEqual(variantQuantitiesArray, item.variants);
});
const PERCENT_TO_NEXT_PRICE_BREAK = 30;
const additionalPropsSelector = (_, {
  isZeroQuantityNotAllowed,
  isNewPriceAllowedToBeEqualCurrent
} = {}) => ({
  isZeroQuantityNotAllowed,
  isNewPriceAllowedToBeEqualCurrent
});
export const upsellWidgetDataForCardSelector = createSelector(upsellItemUpdatedQuantitySelector, itemSelector, inventoryUpsellWereVariantQuantitiesUpdatedSelector, deliverToFulfillmentCenterSelector, (state, props) => ({
  state,
  props
}), (updatedQuantity, item, wasQuantityForUpsellUpdated, deliverToFulfillmentCenterSelected, {
  state,
  props
}) => {
  const upsellData = isBox(item) && deliverToFulfillmentCenterSelected ? upsellBoxItemDataSelector(state, _objectSpread({
    item
  }, props)) : upsellCartItemDataSelector(state, _objectSpread({
    item
  }, props));
  const upsellPresentationData = getInventoryUpsellPresentationData({
    upsellData,
    currentQuantity: updatedQuantity,
    item,
    wasQuantityForUpsellUpdated
  });
  return _objectSpread({}, _omit2(upsellData, 'currentItemPrice', 'newItemPrice'), upsellPresentationData);
});
export const upsellNextPriceBreakCartItemSelector = createSelector((_, props) => props, additionalPropsSelector, inventoryUpsellItemByPropsUpdatedQuantitySelector, itemSelector, sharedItemsOfCardItemWithUpdatedQuantitySelector, stockLevelsByProductIdAndVariantSkuSelector, featureTogglesSelector, ({
  boxQuantity,
  nextPriceBreak
}, {
  isZeroQuantityNotAllowed
}, updatedQuantity, item, sharedItems, stockLevelsByProductIdAndVariantSku, featureFlags) => {
  var _item$prod4;
  if (!item.prod) {
    throw new Error('Item product not found');
  }
  const fullSelectedVariants = item.variants.reduce((acc, selectedVariant) => {
    var _item$prod3;
    const productVariant = (_item$prod3 = item.prod) === null || _item$prod3 === void 0 ? void 0 : _item$prod3.variants[selectedVariant.variantIndex];
    if (productVariant) {
      return [...acc, _objectSpread({}, productVariant, selectedVariant)];
    }
    return acc;
  }, []);
  const product = _objectSpread({}, item.prod, {
    supplierInventoryLevels: stockLevelsByProductIdAndVariantSku[((_item$prod4 = item.prod) === null || _item$prod4 === void 0 ? void 0 : _item$prod4._id) || '']
  });
  const quantity = isZeroQuantityNotAllowed ? updatedQuantity || 1 : updatedQuantity;
  return getNextPriceBreakForCartItem({
    item,
    product,
    sharedItems,
    percentToNextPriceBreak: PERCENT_TO_NEXT_PRICE_BREAK,
    quantity,
    boxQuantity,
    nextPriceBreak,
    selectedVariants: fullSelectedVariants,
    featureFlags
  });
});
export const upsellCartItemDataSelector = createSelector((_, {
  boxQuantity,
  nextPriceBreak
}) => ({
  boxQuantity,
  nextPriceBreak
}), additionalPropsSelector, inventoryUpsellItemByPropsUpdatedQuantitySelector, itemSelector, relatedItemsOfCardItemSelector, sharedItemsOfCardItemWithUpdatedQuantitySelector, stockLevelsByProductIdAndVariantSkuSelector, notSplitSetupFeeSelector, deliverToFulfillmentCenterSelector, featureTogglesSelector, cartItemsSelector, ({
  boxQuantity,
  nextPriceBreak
}, {
  isZeroQuantityNotAllowed,
  isNewPriceAllowedToBeEqualCurrent
}, updatedQuantity, item, relatedItems, sharedItems, stockLevelsByProductIdAndVariantSku, setupFee, deliverToFulfillmentCenterSelected, featureFlags, cartItems) => {
  const quantity = isZeroQuantityNotAllowed ? updatedQuantity || 1 : updatedQuantity;
  return getUpsellWidgetDataForCartItem({
    item,
    relatedItems,
    sharedItems,
    stockLevelsByProductIdAndVariantSku,
    setupFee,
    percentToNextPriceBreak: PERCENT_TO_NEXT_PRICE_BREAK,
    quantity,
    isNewPriceAllowedToBeEqualCurrent,
    showSharedProducts: deliverToFulfillmentCenterSelected,
    boxQuantity,
    nextPriceBreak,
    shouldApplyInitialPB: true,
    shouldShowSplitShipped: true,
    featureFlags,
    allOrderItems: cartItems
  });
});
const boxContentItemsSelector = createSelector(itemSelector, cartItemsSelector, (cartItem, cartItems) => {
  return cartItems.filter(item => item.boxItemsId === cartItem.boxItemsId && !isBox(item) && isItemBoxWithoutSpecialTypes(item));
});
const boxContentSpecialProductsSelector = createSelector(itemSelector, cartItemsSelector, (cartItem, cartItems) => {
  return cartItems.filter(item => item.boxItemsId === cartItem.boxItemsId && !isItemBoxSpecialCrinklePaper(item) && isItemBoxSpecialProduct(item) && !isBox(item));
});
const getUpsellBoxNextPriceBreak = createSelector(inventoryUpsellItemByPropsUpdatedQuantitySelector, boxContentItemsSelector, (state, props) => ({
  state,
  props
}), (boxQuantity, boxContent, {
  state,
  props
}) => {
  let isWithinRange = false;
  let newQuantity = 0;
  let initialPriceBreak;
  boxContent.forEach(item => {
    const {
      newQuantity: itemNewQuantity,
      initialQuantity: itemInitialQuantity,
      isWithinRange: itemIsWithinRange
    } = upsellNextPriceBreakCartItemSelector(state, _objectSpread({}, props, {
      item,
      boxQuantity
    }));
    if (!newQuantity || itemNewQuantity && itemNewQuantity < newQuantity) {
      newQuantity = itemNewQuantity || 0;
    }
    if (itemIsWithinRange) {
      isWithinRange = true;
    }
    if (!initialPriceBreak || itemInitialQuantity && itemInitialQuantity < initialPriceBreak) {
      initialPriceBreak = itemInitialQuantity;
    }
  });
  return {
    newQuantity,
    initialPriceBreak,
    isWithinRange
  };
});
export const upsellBoxItemDataSelector = createSelector(itemSelector, inventoryUpsellItemByPropsUpdatedQuantitySelector, boxContentItemsSelector, boxContentSpecialProductsSelector, getUpsellBoxNextPriceBreak, (state, props) => ({
  state,
  props
}), (cartItem, boxQuantity, boxContent, boxSpecialProducts, {
  newQuantity,
  isWithinRange,
  initialPriceBreak
}, {
  state,
  props
}) => {
  const priceBreaksList = [];
  let showInWidget = true;
  boxContent.forEach(item => {
    const relatedItems = relatedItemsOfCardItemSelector(state, {
      item
    });
    if (relatedItems.length) {
      showInWidget = false;
    }
    const sharedItemsQuantityPerBox = getSharedItemsQuantityPerBox(item, boxContent);
    const boxItemUpsellData = upsellCartItemDataSelector(state, _objectSpread({}, props, {
      item,
      boxQuantity,
      nextPriceBreak: newQuantity * sharedItemsQuantityPerBox
    }));
    priceBreaksList.push(...(boxItemUpsellData.priceBreaks || []));
    item.upsellData = boxItemUpsellData;
  });
  boxSpecialProducts.forEach(item => {
    const boxItemUpsellData = upsellCartItemDataSelector(state, _objectSpread({}, props, {
      item,
      boxQuantity,
      nextPriceBreak: newQuantity * (item.quantityPerBox || 1)
    }));
    item.upsellData = boxItemUpsellData;
  });
  const {
    newItemPrice,
    currentItemPrice
  } = getUpsellBoxPrice({
    boxContentItems: [...boxContent, ...boxSpecialProducts],
    newQuantity,
    boxItem: cartItem
  });
  const formattedPriceBreaks = mergePriceBreaks({
    basePriceQuantities: priceBreaksList
  });
  const result = {
    isWithinRange,
    priceBreaks: formattedPriceBreaks,
    newQuantity: newQuantity || null,
    newItemPrice,
    currentItemPrice,
    showInWidget,
    initialPriceBreak
  };
  return result;
});
export const isCheckoutUpsellQuantityEditedSelector = createSelector(state => state, cartItemsSelector, (state, items) => items.some(item => inventoryUpsellWereVariantQuantitiesUpdatedSelector(state, {
  item
})));
export const inventoryUpsellItemMinProductQuantitySelector = createSelector(inventoryUpsellSelectedItemForEditSelector, featureTogglesSelector, (item, featureToggles) => {
  const {
    dynamicQuantityAndLocationsOptionsForDecorationMethods
  } = featureToggles;
  if (dynamicQuantityAndLocationsOptionsForDecorationMethods && item) {
    const {
      prod
    } = item;
    const chosenProductSettings = prod.productSettings[0];
    return chosenProductSettings.minQuant;
  }
  return (item === null || item === void 0 ? void 0 : item.prod.minQuant) || 1;
});
export const isInventoryUpsellEditedItemBoxSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, item => Boolean(item && isBox(item)));
export const inventoryUpsellSharedItemsForSelectedItemSelector = createSelector(inventoryUpsellSelectedItemForEditSelector, cartItemsSelector, (editedItem, cartItems) => {
  if (!editedItem) {
    return [];
  }
  return getSharedItemsWithItemItself(editedItem, cartItems);
});
export const variantQuantityErrorSelector = createSelector(inventoryUpsellItemMinProductQuantitySelector, upsellTotalItemOverallUpdatedQuantitySelector, isInventoryUpsellEditedItemBoxSelector, inventoryUpsellSharedItemsForSelectedItemSelector, (minQuantity, updatedQuantity, isBoxItemInPopup, sharedItems) => {
  const isMinQuantityCheckSkipped = !isBoxItemInPopup && sharedItems.length < 2;
  if (isMinQuantityCheckSkipped && updatedQuantity < minQuantity) {
    return `There is the min quantity of ${minQuantity} for this product`;
  }
  if (updatedQuantity > MAX_QUANTITY_TO_BUY) {
    return `There is the max quantity of ${MAX_QUANTITY_TO_BUY} for this product`;
  }
  return null;
});
export const variantEditSendToWarehouseErrorSelector = createSelector(upsellTotalVariantsQuantityMapSelector, upsellInventoryQuantitiesMapSelector, shouldShowEditWarehouseQuantityWidget, inventoryUpsellSelectedItemIdSelector, inventoryUpsellSelectedItemForEditSelector, (upsellTotalVariantsQuantityMap, upsellInventoryQuantitiesMap, isShown, id, item) => {
  var _item$prod5, _item$prod5$size;
  if (!isShown || !id) {
    return {};
  }
  const sizesOptions = (item === null || item === void 0 ? void 0 : (_item$prod5 = item.prod) === null || _item$prod5 === void 0 ? void 0 : (_item$prod5$size = _item$prod5.size) === null || _item$prod5$size === void 0 ? void 0 : _item$prod5$size.options) || [];
  const isApparel = item ? checkIsApparel(item.prod) : false;
  const variantQuantities = upsellTotalVariantsQuantityMap[id];
  const inventoryQuantities = upsellInventoryQuantitiesMap[id];
  const quantitiesExceedForColors = {};
  for (const key in variantQuantities) {
    const quantitiesExceed = variantQuantities[key].reduce((acc, quantity, index) => {
      if (quantity < inventoryQuantities[key][index]) {
        acc.push(isApparel ? `${quantity} for ${sizesOptions[Number(index)]}` : String(quantity));
      }
      return acc;
    }, []);
    if (quantitiesExceed.length) {
      quantitiesExceedForColors[key] = `Item quantity being sent to warehouse cannot exceed ${quantitiesExceed.join(', ')}.`;
    }
  }
  return quantitiesExceedForColors;
});
export const inventoryUpsellSelectedBoxApparelItems = createSelector(cartItemsSelector, inventoryUpsellSelectedItemForEditSelector, isInventoryUpsellEditedItemBoxSelector, (cartItems, item, isBoxItemInPopup) => {
  if (!isBoxItemInPopup || !item) {
    return [];
  }
  return getBoxItems(item, cartItems).reduce((acc, currentItem) => {
    if (!checkIsApparel(currentItem.prod)) {
      return acc;
    }
    acc.push(_objectSpread({}, currentItem, {
      _id: String(currentItem._id),
      setupFee: currentItem.setupFee || 0
    }));
    return acc;
  }, []);
});
export const inventoryUpsellHasSelectedBoxApparel = createSelector(inventoryUpsellSelectedBoxApparelItems, selectedBoxApparelItems => Boolean(selectedBoxApparelItems.length));
export const inventoryUpsellBoxSizeOptionsSelector = createSelector(inventoryUpsellSelectedBoxApparelItems, apparelItemsInBox => {
  const apparelSizes = apparelItemsInBox.reduce((acc, item) => {
    var _item$prod6, _item$prod6$size;
    const sizesOptions = item === null || item === void 0 ? void 0 : (_item$prod6 = item.prod) === null || _item$prod6 === void 0 ? void 0 : (_item$prod6$size = _item$prod6.size) === null || _item$prod6$size === void 0 ? void 0 : _item$prod6$size.options;
    if (sizesOptions) {
      acc.push(sizesOptions);
    }
    return acc;
  }, []);
  return _intersection(...apparelSizes);
});
export const inventoryUpsellBoxApparelQuantitiesSelector = createSelector(inventoryUpsellHasSelectedBoxApparel, upsellTotalVariantsQuantityMapSelector, inventoryUpsellSelectedBoxApparelItems, (hasBoxApparel, quantitiesMap, apparelItemsInBox) => {
  if (!hasBoxApparel) {
    return [];
  }
  const firstApparelInBox = apparelItemsInBox[0];
  const firstApparelId = firstApparelInBox._id;
  const apparelQuantities = quantitiesMap === null || quantitiesMap === void 0 ? void 0 : quantitiesMap[firstApparelId];
  const quantitiesEntries = Object.entries(apparelQuantities) || [];
  const [variantIndex, firstVariantSizesQuantities] = quantitiesEntries[0] || [];
  if (!variantIndex || !firstVariantSizesQuantities) {
    return [];
  }
  const quantityPerBox = (firstApparelInBox === null || firstApparelInBox === void 0 ? void 0 : firstApparelInBox.quantityPerBox) || 1;
  const sizesQuantitiesInDB = firstApparelInBox.variants.find(item => {
    return item.variantIndex === Number(variantIndex);
  });
  return firstVariantSizesQuantities.map((quantity, index) => {
    var _sizesQuantitiesInDB$;
    const sizeQuantityInDB = (sizesQuantitiesInDB === null || sizesQuantitiesInDB === void 0 ? void 0 : (_sizesQuantitiesInDB$ = sizesQuantitiesInDB.quantities) === null || _sizesQuantitiesInDB$ === void 0 ? void 0 : _sizesQuantitiesInDB$[index]) || 0;
    const delta = Number(quantity) - sizeQuantityInDB;
    return quantityPerBox > 1 ? Math.floor(delta / quantityPerBox) : delta;
  });
});
export const inventoryUpsellSelectedProductAddedQuantitySelector = createSelector(inventoryUpsellSelectedItemForEditSelector, upsellTotalItemOverallUpdatedQuantitySelector, (item, boxUpdatedQuantity) => {
  if (!(item !== null && item !== void 0 && item.quantity)) {
    return 0;
  }
  return boxUpdatedQuantity - item.quantity;
});
export const inventoryUpsellApparelBoxValidationSelector = createSelector(inventoryUpsellSelectedProductAddedQuantitySelector, variantQuantityErrorSelector, (boxAddedQuantity, variantValidationError) => {
  if (boxAddedQuantity < 0) {
    return 'The quantity of this item cannot be lowered.';
  }
  return variantValidationError;
});
export const inventoryUpsellApparelInBoxValidationSelector = createSelector(inventoryUpsellHasSelectedBoxApparel, inventoryUpsellSelectedProductAddedQuantitySelector, inventoryUpsellBoxApparelQuantitiesSelector, inventoryUpsellApparelBoxValidationSelector, (hasBoxApparel, boxAddedQuantity, apparelAddedQuantitiesInBox, boxValidationMessage) => {
  if (!hasBoxApparel || boxValidationMessage) {
    return null;
  }
  const amountOfAllApparelInBox = apparelAddedQuantitiesInBox.reduce((acc, curr) => acc + curr, 0);
  if (amountOfAllApparelInBox !== boxAddedQuantity) {
    return `Please update your sizes to get to a total of ${boxAddedQuantity}.`;
  }
  return null;
});
export const isSendToInventoryConfirmationShownSelector = createSelector(upsellInventoryNonZeroQuantitiesMapSelector, deliverToFulfillmentCenterSelector, (upsellInventoryQuantitiesMap, deliverToFulfillmentCenter) => !deliverToFulfillmentCenter && !!Object.keys(upsellInventoryQuantitiesMap).length);
const getSplitShippedItemsIds = (allIds, toInventory) => {
  return allIds.filter(id => toInventory.includes(id));
};
function subtractQuantities(totalVariants, toInventoryVariants) {
  const result = [];
  const len = Math.max(totalVariants.length, toInventoryVariants.length);
  for (let i = 0; i < len; i++) {
    result.push((totalVariants[i] || 0) - (toInventoryVariants[i] || 0));
  }
  return result;
}
const getItemsWithSplitShipped = (splitShippedItemsIds, toInventoryMap, items, inventoryId, state) => {
  const splitShippedItems = [];
  const itemsCopy = _cloneDeep2(items);
  splitShippedItemsIds.forEach(id => {
    const sourceItem = itemsCopy.find(item => item._id === id);
    if (!sourceItem) {
      return;
    }
    const toInventoryQuantity = Object.values(toInventoryMap[sourceItem._id]).reduce((acc, element) => {
      const variantsSum = element.reduce((acc, e) => acc + e, 0);
      return acc + variantsSum;
    }, 0);
    const upsellData = upsellWidgetDataForCardSelector(state, {
      item: sourceItem,
      isEditMode: true
    });
    const inventoryCouple = getSplitShippedCouple({
      item: sourceItem,
      allItems: itemsCopy
    });
    if (checkIsSplitShippedToCustomer(sourceItem) && inventoryCouple) {
      const isNotInArray = splitShippedItems.every(({
        _id
      }) => !_id || _id !== inventoryCouple._id);
      if (isNotInArray) {
        splitShippedItems.push(_objectSpread({}, inventoryCouple, {
          _id: String(inventoryCouple._id),
          quantity: toInventoryQuantity,
          variants: Object.entries(toInventoryMap[sourceItem._id]).map(([variantIndex, quantities]) => ({
            variantIndex: Number(variantIndex),
            quantities
          }))
        }));
      }
      return;
    }
    if (checkIsSplitShippedToInventory(sourceItem)) {
      const isNotInArray = splitShippedItems.every(({
        _id
      }) => !_id || _id !== sourceItem._id);
      if (isNotInArray) {
        splitShippedItems.push(_objectSpread({}, sourceItem, {
          quantity: toInventoryQuantity,
          variants: Object.entries(toInventoryMap[sourceItem._id]).map(([variantIndex, quantities]) => ({
            variantIndex: Number(variantIndex),
            quantities
          }))
        }));
      }
      return;
    }
    const sourceItemNormalized = _omit2(sourceItem, '_id');
    const asRelatedItemId = sourceItemNormalized.asRelatedItemId || uuidV1();
    const newItem = _objectSpread({}, sourceItemNormalized, {
      initialQuantity: 0,
      asRelatedItemId,
      inventoryId,
      quantity: toInventoryQuantity,
      variants: Object.entries(toInventoryMap[sourceItem._id]).reduce((result, [variantIndex, quantities]) => {
        if (_sum2(quantities) > 0) {
          result.push({
            variantIndex: Number(variantIndex),
            quantities
          });
        }
        return result;
      }, []),
      splitShippedType: SPLIT_SHIPPED_ITEM_TYPE.toInventory
    });
    if (!sourceItemNormalized.asRelatedItemId) {
      sourceItem.asRelatedItemId = asRelatedItemId;
    }
    newItem.inventoryUpsellStatus = upsellData.quantityWasUpdated ? upsellData.upsellStatus : INVENTORY_UPSELL_STATUSES.INCREASE;
    splitShippedItems.push(newItem);
  });
  const itemsWithUpsell = itemsCopy.reduce((acc, item) => {
    let newItem = _cloneDeep2(item);
    if (checkIsSplitShippedToInventory(item)) {
      newItem = _omit2(newItem, '_id');
      const toCustomerCouple = getSplitShippedCouple({
        item,
        allItems: itemsCopy
      });
      if (toCustomerCouple) {
        return acc;
      }
    }
    const upsellData = upsellWidgetDataForCardSelector(state, {
      item,
      isEditMode: true
    });
    const itemUpdatedQuantityTotal = inventoryUpsellItemByPropsUpdatedQuantitySelector(state, {
      item,
      isEditMode: true
    });
    const splitShippedItem = splitShippedItems.find(splitShippedItem => {
      const itemsNotInBox = !item.boxItemsId && !splitShippedItem.boxItemsId;
      return itemsNotInBox && splitShippedItem.asRelatedItemId === newItem.asRelatedItemId && splitShippedItem.prodId === newItem.prodId && splitShippedItem._id !== newItem._id;
    });
    newItem.splitShippedType = splitShippedItem ? SPLIT_SHIPPED_ITEM_TYPE.toCustomer : null;
    if (typeof newItem.initialQuantity !== 'number') {
      newItem.initialQuantity = item.quantity || 0;
    }
    newItem.quantity = splitShippedItem ? itemUpdatedQuantityTotal - splitShippedItem.quantity : itemUpdatedQuantityTotal;
    const sharedItems = sharedItemsOfCardItemWithUpdatedQuantitySelector(state, {
      isEditMode: true,
      item: newItem
    });
    const sharedItemsToUse = splitShippedItem ? [...sharedItems, splitShippedItem] : sharedItems;
    if (!newItem._id) {
      sharedItemsToUse.push(newItem);
    }
    const blendedQuantity = getTotalQuantityForSharedItems(newItem, sharedItemsToUse);
    newItem.blendedQuantity = blendedQuantity;
    if (splitShippedItem) {
      splitShippedItem.blendedQuantity = blendedQuantity;
    }
    if (newItem.quantity === 0) {
      return acc;
    }
    const variantQuantitiesMap = upsellTotalVariantsQuantityMapSelector(state);
    const itemQuantities = variantQuantitiesMap[item._id];
    if (itemQuantities) {
      newItem.variants = Object.entries(itemQuantities).map(([variantIndex, quantities]) => {
        if (splitShippedItem) {
          const neededVariant = splitShippedItem.variants.find(variant => variant.variantIndex === Number(variantIndex));
          const newQuantities = neededVariant !== null && neededVariant !== void 0 && neededVariant.quantities ? subtractQuantities(quantities, neededVariant === null || neededVariant === void 0 ? void 0 : neededVariant.quantities) : quantities;
          return {
            variantIndex: Number(variantIndex),
            quantities: newQuantities
          };
        }
        return {
          variantIndex: Number(variantIndex),
          quantities
        };
      });
    }
    if (upsellData.quantityWasUpdated) {
      newItem.inventoryUpsellStatus = upsellData.upsellStatus;
    }
    return [...acc, newItem];
  }, []);
  return [...itemsWithUpsell, ...splitShippedItems];
};
const getItemsListByUpsell = ({
  totalMap,
  toInventoryMap,
  items,
  inventoryId,
  state
}) => {
  const allUpsellIds = Object.keys(totalMap);
  const toInventoryIds = Object.keys(toInventoryMap);
  const hasUpsellItemsTotal = allUpsellIds.length > 0;
  const hasUpsellItemsToInventory = toInventoryIds.length > 0;
  if (!hasUpsellItemsTotal && !hasUpsellItemsToInventory) {
    return items;
  }
  const splitShippedItemsIds = getSplitShippedItemsIds(allUpsellIds, toInventoryIds);
  const updatedItems = getItemsWithSplitShipped(splitShippedItemsIds, toInventoryMap, items, inventoryId, state);
  return updatedItems;
};
export const getUpdatedItemsWithUpsell = state => {
  const items = cartItemsSelector(state);
  const itemsTotalMap = upsellTotalVariantsQuantityMapSelector(state);
  const itemsToInventory = upsellInventoryNonZeroQuantitiesMapSelector(state);
  const inventoryId = selectedInventoryIdSelector(state);
  const updatedItems = getItemsListByUpsell({
    totalMap: itemsTotalMap,
    toInventoryMap: itemsToInventory,
    items,
    inventoryId,
    state
  });
  return updatedItems;
};